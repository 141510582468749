<template>
  <div id="">
    <transition name="fade">
      <div v-if="show" class="wrapper wrapper-content animated fadeInRight"
        style="background-color: white; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
        <div style="background-color: white;">
          <div style="margin-bottom: 10px;font-size: 18px;color: #3c8dbc; font-weight: 700;">
            <span>订单管理</span>
            <div>
              <div style="margin-bottom: 6px;">
                <el-col :span="2.5">
                  <el-radio-group v-model="type" fill="#67c03a" text-color="#ffffff" @change="getOrderList()">
                    <el-radio-button label="出库"></el-radio-button>
                    <el-radio-button label="入库"></el-radio-button>
                  </el-radio-group>
                </el-col>
                <el-col :span="2.5">
                  <div style="margin-top: 10px;margin-left: 16px;">
                    <el-switch style="display: block" v-model="status" active-color="#13ce66" @change="getList()"
                      inactive-color="#ff4949" active-text="已审批" inactive-text="未审批">
                    </el-switch>
                  </div>
                </el-col>
              </div>

              <div style="width: 100%;">
                <el-col :span="2">
                  <el-date-picker type="date" placeholder="起始日期" v-model="pageInfo.start"
                    style="width: 140px;  margin-left: 20px;">
                  </el-date-picker>
                </el-col>
                <el-col class="line" :span="0.5">
                  <div style="margin: 10px; text-align: center;margin-left: 30px;">-</div>
                </el-col>
                <el-col :span="2">
                  <el-date-picker type="date" placeholder="结束日期" v-model="pageInfo.end" style="width: 140px;">
                  </el-date-picker>
                </el-col>
                <el-col class="line" :span="0.5">
                  <div style="margin: 10px;"></div>
                </el-col>
                <el-col class="line" :span="0.5">
                  <div style="margin-right: 16px;">
                    <el-input v-model="pageInfo.query" placeholder="输入订单号" @keyup.enter.native="getList()">
                    </el-input>
                  </div>
                </el-col>
                <el-button icon="el-icon-search" type="success" @click="getList()">查询</el-button>
                <el-button type="success" icon="el-icon-edit" plain @click="addOrder"
                  style="position: absolute;right: 30px;">订单创建</el-button>
              </div>

            </div>
          </div>

          <!-- 订单信息 -->
          <div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="订单信息" name="1">
                <div class="block">
                  <el-table :data="Orders" style="width: 100% " :row-class-name="tableRowClassName">
                    <el-table-column prop="id" width="100" label="订单号" align="center">
                    </el-table-column>
                    <el-table-column prop="created" label="订单日期" :formatter="dateFormat" align="center" width="180%">
                    </el-table-column>
                    <el-table-column prop="traderId" :label="type == '出库' ? '客户名':'供应商'" :formatter="setTraderName"
                      align="center" width="300%">
                    </el-table-column>
                    <el-table-column prop="productId" label="产品名称" align="center" :formatter="setProductName">
                    </el-table-column>
                    <el-table-column prop="productId" label="型号" align="center" :formatter="setProductModule">
                    </el-table-column>
                    <el-table-column prop="number" label="数量" align="center" width="120%" :formatter="setProductUnit">
                    </el-table-column>
                    <el-table-column prop="deleteFlag" label="总价" align="center" width="120%"
                      :formatter="setProductTotal">
                    </el-table-column>
                    <el-table-column prop="status" label="状态" align="center" width="120%">
                      <template slot-scope="scope">
                        <div id="">
                          <el-button size="small" plain type="warning" v-if="scope.row.status"><i
                              class="el-icon-loading"></i> 待审批</el-button>
                          <el-button size="small" plain type="success" v-if="!scope.row.status"><i
                              class="el-icon-finished"></i> 已完成</el-button>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" align="center" width="200%">
                      <template slot-scope="scope">
                        <el-row style="font-size: 18px;">
                          <el-button type="primary" plain size="small" @click="showOrder(scope.row)"><i
                              class="el-icon-tickets"> </i> 查看</el-button>
                        </el-row>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNum" :page-sizes="[4, 8, 16]" :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="totalPage">
                  </el-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>

        </div>
      </div>
    </transition>
    <el-dialog title="查看订单" :visible.sync="orderShowVisible">
      <div style="text-align: center;width: 95%; margin-top: 20px;">
        <el-form :model="dialogOrder" label-width="180px">
          <el-form-item label="产品名称">
            <div v-text="setProductName(dialogOrder,'')"></div>
          </el-form-item>
          <el-form-item label="数量">
            <div v-text="setProductUnit(dialogOrder,'')"></div>
          </el-form-item>
          <el-form-item label="总价">
            <div v-text="setProductTotal(dialogOrder,'')"></div>
          </el-form-item>
          <el-form-item label="交易日期">
            <div v-text="dateFormat(dialogOrder,'')"></div>
          </el-form-item>
          <el-form-item :label="type == '出库' ? '客户名':'供应商'">
            <div v-text="setTraderName(dialogOrder,'')"></div>
          </el-form-item>
          <el-form-item label="" label-width="120px" style="text-align: center;width: 96%;">
            <el-button @click="orderShowVisible = false" plain><i class="el-icon-close"></i> 关 闭
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog title="创建订单" :visible.sync="newOrderVisible">
      <el-form :model="newOrder" label-width="220px" class="demo-ruleForm" style="text-align: center;">
        <el-form-item label="订单类型" style="width: 80%;">
          <el-radio-group v-model="newOrder.type" fill="#67c03a" text-color="#ffffff" @change="setTraders">
            <el-radio-button label="出库"></el-radio-button>
            <el-radio-button label="入库"></el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="newOrder.type =='出库'?'客户':'供应商'" style="width: 80%;">
          <el-select v-model="newOrder.traderId" placeholder="请选择分类">
            <el-option v-for="item in traderList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品名称" style="width: 80%;">
          <el-select v-model="newOrder.productId" placeholder="请选择分类">
            <el-option v-for="item in Products" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="商品信息" style="width: 80%;">
          <el-form :label-position="labelPosition" label-width="80px" style="text-align: left;">
            <el-form-item label="型号：">
              <div v-text="setProductModule(newOrder,'')"></div>
            </el-form-item>
            <el-form-item label="单价：">
              <div v-text="setProductUnitPrice(newOrder,'')"></div>
            </el-form-item>
            <el-form-item label="类型：">
              <div v-text="setProductType(newOrder,'')"></div>
            </el-form-item>
          </el-form>
        </el-form-item>

        <el-form-item label="数量" style="width: 80%;">
          <el-input v-model="newOrder.number" type="number" style="width: 80%;"></el-input>
        </el-form-item>
        <el-form-item label="总价" style="width: 80%;">
          <div v-text="setProductTotal(newOrder,'')"></div>
        </el-form-item>
        <el-form-item style="width: 80%; text-align: center;">
          <el-button @click="newOrderVisible = false">取 消</el-button>
          <el-button type="primary" @click="sendNewOrder">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created() {
      this.getUserInfo()
      this.getProducts()
      this.getTraders()
    },
    mounted() {
      this.getOrderList()
    },
    watch: {},
    methods: {
      async sendNewOrder() {
        this.newOrderVisible = false
        let userId = window.sessionStorage.getItem('userId')
        this.newOrder.operId = userId
        const {
          data: result
        } = await this.$http.post("/order/add/", this.newOrder)
        if (result.status !== 200) return this.$message.error("订单创建失败！")
        this.$message.success("订单创建成功！")
        this.newOrder = {
          number: '',
          operId: '',
          productId: '',
          traderId: '',
          type: '出库'
        }
        this.getOrderList()
      },
      addOrder() {
        this.newOrderVisible = true
        this.setTraders()
      },
      setTraders() {
        var trader = this.Traders
        console.log(trader)
        this.traderList = []
        if (this.newOrder.type == '出库') {
          var i = 0
          while (trader[i].type) {
            if (trader[i].type == '客户')
              this.traderList.push(trader[i])
            i++
          }
        } else {
          var i = 0
          while (trader[i].type) {
            if (trader[i].type == '供应')
              this.traderList.push(trader[i])
            i++
          }
        }
        this.newOrder.traderId = ''
      },
      getList() {
        if (this.activeName == 1) {
          this.getOrderList()
        } else {
          this.getBillList()
        }
      },
      showDeleteBill(val) {
        this.orderId = val.orderId
        this.deleteBillVisible = true
      },
      async deleteBill() {
        this.deleteBillVisible = false
        const {
          data: result
        } = await this.$http.delete("/bill/deleteById/" + this.orderId)
        if (result.status !== 200) return this.$message.error("发票删除失败")
        this.getBillList()
      },
      async getBill() {
        this.orderShowVisible = false
        var userId = window.sessionStorage.getItem('userId')
        window.location.href = `http://manage.zhiwei.com/download/tip/${this.orderId}/${userId}`
      },
      downLoadBill(val) {
        var userId = window.sessionStorage.getItem('userId')
        var orderId = val.orderId
        window.location.href = `http://manage.zhiwei.com/download/tip/${orderId}/${userId}`
      },
      checkOrder(val) {
        this.orderConfirmVisible = true
        // this.orderId = val.id
        this.confirmOrder = val
      },
      setProductType(row) {
        var id = row.productId
        for (var p of this.Products) {
          if (p.id == id) return p.type
        }
      },
      setProductUnitPrice(row) {
        var id = row.productId
        for (var p of this.Products) {
          if (p.id == id) return p.unitPrice + ".00元"
        }
      },
      setProductModule(row) {
        var id = row.productId
        for (var p of this.Products) {
          if (p.id == id) return p.model
        }
      },
      setProductTotal(row) {
        var num = row.number
        var id = row.productId
        for (var p of this.Products) {
          if (p.id == id) return num * p.unitPrice + ".00元"
        }
      },
      setProductUnit(row) {
        var num = row.number
        var id = row.productId
        for (var p of this.Products) {
          if (p.id == id) return num + p.unit
        }
      },
      setTraderName(row) {
        var id = row.traderId
        for (var t of this.Traders) {
          if (t.id == id) return t.name
        }
      },
      setProductName(row) {
        var id = row.productId
        for (var p of this.Products) {
          if (p.id == id) return p.name
        }
      },
 
      dateFormat: function(row, column) {
        var t = new Date(row.created); //row 表示一行数据, updateTime 表示要格式化的字段名称
        return t.getFullYear() + "年" + (t.getMonth() + 1) + "月" + t.getDate() + "日";
      },
      moneyFormat: function(row, column) {
        var t = row.unitPrice; //row 表示一行数据, updateTime 表示要格式化的字段名称
        return t + ".00元";
      },
      async getUserInfo() {
        let userId = window.sessionStorage.getItem('userId')
        const {
          data: result
        } = await this.$http.get("/user/getById/" + userId)
        this.UserInfo = result.data
        // console.log(this.UserInfo)
      },
      async getTraders() {
        const {
          data: result
        } = await this.$http.get("/trader/allList")
        if (result.status !== 200) return this.$message.error("供应商列表查询失败")
        this.Traders = result.data
        console.log(this.Traders)
      },
      async getProducts() {
        const {
          data: result
        } = await this.$http.get("/products/allList")
        if (result.status !== 200) return this.$message.error("产品列表查询失败")
        this.Products = result.data
        console.log('zheliqqqqqqqqqqqqqqq')
        console.log(this.Products)
      },
      //获取发票列表
      async getBillList() {
        const {
          data: result
        } = await this.$http.get("/bill/list", {
          params: this.pageInfo
        })
        if (result.status !== 200) return this.$message.error("发票列表查询失败")
        console.log(result.data)
        this.billList = result.data.rows
        this.totalPage = result.data.total
        this.pageInfo.query = ''
      },
      async getOrderList() {
        if (this.status == false) {
          this.pageInfo.status = 1
        } else if (this.status == true) {
          this.pageInfo.status = 0
        }
        if (this.type == '出库') {
          this.pageInfo.type = 1
        } else if (this.type == '入库') {
          this.pageInfo.type = 0
        } else {}
        var userId = window.sessionStorage.getItem('userId')
        this.pageInfo.userId = userId
        const {
          data: result
        } = await this.$http.get('/order/myOrderList', {
          params: this.pageInfo
        })
        if (result.status !== 200) return this.$message.error("申请单列表查询失败")
        this.Orders = result.data.rows
        this.totalPage = result.data.total
        this.pageInfo.query = ''
        console.log(this.Orders)
      },
      showOrder(order) {
        this.orderShowVisible = true
        this.dialogOrder = order
        this.orderId = order.id
        console.log(order)
        var userId = window.sessionStorage.getItem('userId')
        // this.downLoadHref = `http://localhost:6660/download/tip/${this.orderId}/${userId}`
      },
      handleClick(tab, event) {
        this.getList()
        this.pageInfo.start = ''
        this.pageInfo.end = ''
      },
      tableRowClassName({
        row,
        rowIndex
      }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },

      handleSizeChange(val) {
        this.pageInfo.pageSize = val
        this.getList()
        // console.log(this.pageInfo);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNum = val
        this.getList()
        // console.log(`当前页: ${val}`);
      },
      open1() {
        this.$notify({
          title: '成功',
          message: '操作成功！',
          type: 'success'
        });
      },

      open2() {
        this.$notify({
          title: '警告',
          message: '请考虑后在操作！',
          type: 'warning'
        });
      },

      open3() {
        this.$notify.info({
          title: '消息',
          message: '这是一条消息的提示消息'
        });
      },

      open4() {
        this.$notify.error({
          title: '错误',
          message: '操作失败！'
        });
      }
    },
    data() {
      return {
        needproduct: '',
        traderList: [],
        newOrder: {
          number: '',
          operId: '',
          productId: '',
          traderId: '',
          type: '出库'
        },
        deleteBillVisible: false,
        downLoadHref: '',
        orderId: '',
        billList: '',
        confirmOrder: '',
        updateMsg: '',
        UserInfo: '',
        Traders: '',
        Products: '',
        newOrderVisible: false,
        orderShowVisible: false,
        leaveVisible: false,
        dialogOrder: '',
        Orders: '',
        request: '',
        product: '',
        type: '出库',
        status: false,
        pageInfo: {
          userId: '',
          type: "出库",
          start: '',
          end: '',
          query: '',
          status: 1,
          pageNum: 1,
          pageSize: 8
        },
        vacationObj: {
          processors: [2, 3]
        },
        vacationVisible: false,
        aplayActiveName: '1',
        activeName: '1',
        formLabelWidth: '90px',
        dialogFormVisible: false,
        show: true,
        currentPage1: 5,
        currentPage2: 5,
        currentPage3: 5,
        totalPage: 4,
      };
    }
  };
</script>

<style scoped lang="less">
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }

  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
