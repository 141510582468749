<template>
  <div>
    <div class="login-body"><img src="../../public/background.jpg">
      <div id="ad" class="box-shadow" style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
        <div class="ui container" >
          <div class="ui link list" style="text-align: center;margin-top: 16px;">
            <div class="item">
              <a class="item" href="/about_us"><i class="el-icon-service"></i> 关于我们</a>
            </div>
            <div class="item">
              <a class="item" href="/oschina/git-osc/issues"><i class="el-icon-chat-dot-round"></i> 意见建议</a>
            </div>
            <div class="item">
              <a class="item" href="/links"><i class="el-icon-tickets"></i> 合作伙伴</a>
            </div>
          </div>
          <div class="name-important" style="text-align: center;font-size: 15px; margin-top: -8px;">
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.2" baseProfile="tiny" id="图层_1" x="0px" y="0px" viewBox="0 0 214 218.9" overflow="visible" xml:space="preserve">
              <path d="M157.2,146.6c-0.4,2.9,1.1,5,2.6,7.3c1.3,2,2.9,4.1,3.2,6.3c1,6.3-2.5,11.2-9.4,10.2c-2.2-0.3-4.3-1.7-6.3-2.8  c-4.6-2.8-9-5.8-13.5-8.6c-2.9-1.8-4-4.6-4.7-7.7c-0.9-4-1.7-8.1-2.5-12.1c-0.2-1.1-0.2-2.3-0.4-3.5c-0.3-1.5-0.6-3-1.2-4.4  c-0.7-1.5-1.5-2.9-0.5-4.4c0.9-1.3,2.4-0.9,3.7-0.7c1.5,0.2,3.1,0.1,4.6,0.4c1.7,0.4,3.2,1.1,4.8,1.7c0.2,0.1,0.4,0.4,0.6,0.4  c6.2,1.2,9.5,6.4,14,10c1.1,0.9,2.1,1.9,3.2,2.8C157.3,142.9,157.3,144.7,157.2,146.6z"/>
              <path fill="#0D0000" d="M47.3,133.8c1.1-2.4,19.2-12.3,25.4-19.1c3.3-3.5,9.3-7.7,13.7-10.9c3.1-4.7,9.3-7,13.4-10.9  c3.9-1.6,3.4-33.9,2.4-39.1c1-8.7-1.1-17.5-1.4-26.1c0.3-4.3-3.8-7-5.4-10.7C93.6,12.6,79.8,9,88,4.2c4-0.6,7.9-0.1,11.1-3.3  c10.5-3.3,25.1,9.1,35.2,12.7c4.3,2.1,9.3,9.2,4.2,13.1c-1.8,1.3-2.2,3.1-2.2,5.1c1.5,7.2-1.3,14.2-4.9,20.3  c-2.7,3.2-4.8,7.4-4.8,11.5c0.8,5,2.1,9.6,0.3,14.7c26-10.2,59.4-0.9,79.5,17.7c9.1,6.5,5,19.4,5.3,29.1c-0.1,3.9-1.1,7.5-2.8,10.9  c-3.9,3.2-3,8-4.2,12.1c-1.9,7.8-6.6,14.3-11.2,20.7c-2.9,1-2.8,3.3-3.1,5.8c-0.4,4.8-4.6,7.7-7.4,11.2c-1.1,2.8-3.5,3.8-6.1,4.1  c-5.4,0.7-0.7,7.7-9.6,11.6c-6.2,3.7-9.9,12.4-18.3,10.4c-2.6-0.1-4.8,0.5-6.5,2.6c-2.4,1.6-5.6,2.4-8.1,3.9  c-2.6,1.4-4.8,0.3-7.1-0.7c-1.7-0.6-4-0.2-5.6-1.1c-2.1-3.1,3.2-3.1,4.5-5c4.1-5.5,10.2-10.5,17-11.8c6.4-4.1,10-11.1,15.9-15.5  c1.6-0.8,2.7-1.9,3.3-3.7c1.5-5.6,5.8-9.2,9.6-13.3c2.8-4.6,2.7-10.1,6-14.6c4.2-7.4,5.8-15.5,5.9-24c4.5-10.4,4.1-21.8-1.2-31.9  c-10.5-12.3-27-11.6-41.2-7.3c-13.3,3.7-24,14.2-31.7,25.2c-0.6,1.3-1.2,2.3-2.8,2.6c-5.8,2.2-4.5,10.6-9.4,13.8  c-3.6,1.7-2.7,5.9-3.9,9c-1,2.2-4.3,4.2-5.4,0.9c-0.1-0.2-0.4-0.3-1-0.9c-0.8,5.8-1.5,10.9-6,14.9c-2.3,1.8-1.3,6.4-4.8,6.7  c-1.7,0.2-2.2,1.4-2.1,3c0.8,4.3-4.8,5.1-5,9.1c-0.5,3.4-2.9,5.5-5.4,7.5c-5.2,2.6-8,8.4-13.3,10.7c-2.1,0.7-4.1,1.1-5.8,2.8  c-5.3,3.8-11.7-3.3-16.9-4.9c-1.3-0.7-3.4-1.1-3.8-2.2c-2.2-8.4-0.2-28.4,8.1-33c1.5-0.8,2.5-1.5,2.1-3.4c0.2-6.2,8.2-15.2,13.6-18"/>
              <path fill="#FFF5F5" d="M47.3,167.5c0.5,2.1,0.5,5,3.2,4.6c1.2-0.1,1.6-0.9,1.5-2.1c-0.1-1.9,1.1-2.8,2.8-3.2c2.1-0.6,4.7,0,5.1-2.7  c0.8-2.9,0.7-6.1,4.4-6.4c4-0.7-0.1-5.7,5.1-8.1c4-2.3,5.6-6.9,8.9-9.9c1.8-1.9,4.4-3.2,4.7-6.4c-0.1-4.7,5.4-0.5,5.4-4.2  c-2.7-8.7,4.3-11.1,5.4-19c-6-0.6-8.3,7.3-11.8,11c-3.6,2.2-6.1,5.7-8.8,8.9c-6.4,4.5-11.8,6.5-14.1,14.9  C56.6,153.5,49,159,47.3,167.5z"/>
              <g>
              	<path d="M72.4,41.9L50.4,95l22.9-19.6l3.1-15.5l9.1,31.6l-57.2,28.3l10.6-53.9L21.9,76.6l-1.6,14L6.9,62.2L72.4,41.9z"/>
              </g>
              <g>
              	<path fill="rgb(0,113,188)" d="M66,39.5L44,92.7L66.9,73L70,57.5L79,89.1l-57.2,28.3l10.6-53.9L15.5,74.3l-1.6,14L0.5,59.8L66,39.5z"/>
              </g>
              </svg>
            </i> 成都市智为科技有限公司版权所有
          </div>
          <div style="text-align: center;">
            <span>@ 2021 The Zhiwei Authors | Zhiwei ERP Project</span>
          </div>
        </div>
      </div>
    </div>
    <div id="control">
      <transition>
        <h4 v-if="headTitle.flag">
          <div id="form">
            <div style="font-size: 36px;font-weight: 700;text-align: center;margin-top: 60px;color: #409EFF;">智为用户登录
            </div>
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
              class="demo-ruleForm" @submit.native.prevent>
              <el-form-item label="用户名" prop="loginName">
                <el-input prefix-icon="el-icon-user" v-model="ruleForm.loginName"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input prefix-icon="el-icon-unlock" type="password"
                  v-model="ruleForm.password"></el-input>
              </el-form-item>
              <el-form-item>
                <div style="text-align: center;width: 85%;">
                  <el-button type="primary" @click.stop="submitForm" native-type="submit">登录</el-button>
                  <el-button @click="resetForm">重置</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div id="title-head">
            <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.2" baseProfile="tiny" id="图层_1" x="0px" y="0px" viewBox="0 0 214 218.9" overflow="visible" xml:space="preserve">
            <path d="M157.2,146.6c-0.4,2.9,1.1,5,2.6,7.3c1.3,2,2.9,4.1,3.2,6.3c1,6.3-2.5,11.2-9.4,10.2c-2.2-0.3-4.3-1.7-6.3-2.8  c-4.6-2.8-9-5.8-13.5-8.6c-2.9-1.8-4-4.6-4.7-7.7c-0.9-4-1.7-8.1-2.5-12.1c-0.2-1.1-0.2-2.3-0.4-3.5c-0.3-1.5-0.6-3-1.2-4.4  c-0.7-1.5-1.5-2.9-0.5-4.4c0.9-1.3,2.4-0.9,3.7-0.7c1.5,0.2,3.1,0.1,4.6,0.4c1.7,0.4,3.2,1.1,4.8,1.7c0.2,0.1,0.4,0.4,0.6,0.4  c6.2,1.2,9.5,6.4,14,10c1.1,0.9,2.1,1.9,3.2,2.8C157.3,142.9,157.3,144.7,157.2,146.6z"/>
            <path fill="#0D0000" d="M47.3,133.8c1.1-2.4,19.2-12.3,25.4-19.1c3.3-3.5,9.3-7.7,13.7-10.9c3.1-4.7,9.3-7,13.4-10.9  c3.9-1.6,3.4-33.9,2.4-39.1c1-8.7-1.1-17.5-1.4-26.1c0.3-4.3-3.8-7-5.4-10.7C93.6,12.6,79.8,9,88,4.2c4-0.6,7.9-0.1,11.1-3.3  c10.5-3.3,25.1,9.1,35.2,12.7c4.3,2.1,9.3,9.2,4.2,13.1c-1.8,1.3-2.2,3.1-2.2,5.1c1.5,7.2-1.3,14.2-4.9,20.3  c-2.7,3.2-4.8,7.4-4.8,11.5c0.8,5,2.1,9.6,0.3,14.7c26-10.2,59.4-0.9,79.5,17.7c9.1,6.5,5,19.4,5.3,29.1c-0.1,3.9-1.1,7.5-2.8,10.9  c-3.9,3.2-3,8-4.2,12.1c-1.9,7.8-6.6,14.3-11.2,20.7c-2.9,1-2.8,3.3-3.1,5.8c-0.4,4.8-4.6,7.7-7.4,11.2c-1.1,2.8-3.5,3.8-6.1,4.1  c-5.4,0.7-0.7,7.7-9.6,11.6c-6.2,3.7-9.9,12.4-18.3,10.4c-2.6-0.1-4.8,0.5-6.5,2.6c-2.4,1.6-5.6,2.4-8.1,3.9  c-2.6,1.4-4.8,0.3-7.1-0.7c-1.7-0.6-4-0.2-5.6-1.1c-2.1-3.1,3.2-3.1,4.5-5c4.1-5.5,10.2-10.5,17-11.8c6.4-4.1,10-11.1,15.9-15.5  c1.6-0.8,2.7-1.9,3.3-3.7c1.5-5.6,5.8-9.2,9.6-13.3c2.8-4.6,2.7-10.1,6-14.6c4.2-7.4,5.8-15.5,5.9-24c4.5-10.4,4.1-21.8-1.2-31.9  c-10.5-12.3-27-11.6-41.2-7.3c-13.3,3.7-24,14.2-31.7,25.2c-0.6,1.3-1.2,2.3-2.8,2.6c-5.8,2.2-4.5,10.6-9.4,13.8  c-3.6,1.7-2.7,5.9-3.9,9c-1,2.2-4.3,4.2-5.4,0.9c-0.1-0.2-0.4-0.3-1-0.9c-0.8,5.8-1.5,10.9-6,14.9c-2.3,1.8-1.3,6.4-4.8,6.7  c-1.7,0.2-2.2,1.4-2.1,3c0.8,4.3-4.8,5.1-5,9.1c-0.5,3.4-2.9,5.5-5.4,7.5c-5.2,2.6-8,8.4-13.3,10.7c-2.1,0.7-4.1,1.1-5.8,2.8  c-5.3,3.8-11.7-3.3-16.9-4.9c-1.3-0.7-3.4-1.1-3.8-2.2c-2.2-8.4-0.2-28.4,8.1-33c1.5-0.8,2.5-1.5,2.1-3.4c0.2-6.2,8.2-15.2,13.6-18"/>
            <path fill="#FFF5F5" d="M47.3,167.5c0.5,2.1,0.5,5,3.2,4.6c1.2-0.1,1.6-0.9,1.5-2.1c-0.1-1.9,1.1-2.8,2.8-3.2c2.1-0.6,4.7,0,5.1-2.7  c0.8-2.9,0.7-6.1,4.4-6.4c4-0.7-0.1-5.7,5.1-8.1c4-2.3,5.6-6.9,8.9-9.9c1.8-1.9,4.4-3.2,4.7-6.4c-0.1-4.7,5.4-0.5,5.4-4.2  c-2.7-8.7,4.3-11.1,5.4-19c-6-0.6-8.3,7.3-11.8,11c-3.6,2.2-6.1,5.7-8.8,8.9c-6.4,4.5-11.8,6.5-14.1,14.9  C56.6,153.5,49,159,47.3,167.5z"/>
            <g>
            	<path d="M72.4,41.9L50.4,95l22.9-19.6l3.1-15.5l9.1,31.6l-57.2,28.3l10.6-53.9L21.9,76.6l-1.6,14L6.9,62.2L72.4,41.9z"/>
            </g>
            <g>
            	<path fill="rgb(0,113,188)" d="M66,39.5L44,92.7L66.9,73L70,57.5L79,89.1l-57.2,28.3l10.6-53.9L15.5,74.3l-1.6,14L0.5,59.8L66,39.5z"/>
            </g>
            </svg>
          </div>
        </h4>

      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    created(){
      setTimeout(() =>{
          this.headTitle.flag = true
      },500);
    },
    data() {
      const findUser = async (rule, value, callback) => {
        //查找用户是否存在
       const {
         data: result
       } = await this.$http.get('/user/find/'+value)
        //判断返回值内容
        if (result.data == 1) {
          //用户存在 正确返回
          return callback()
        }
        callback(new Error('用户不存在！'))
      }
      return {
        ruleForm: {
          loginName: '',
          password: ''
        },
        rules: {
          loginName: [{
              required: true,
              message: '请输入登录用户名',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 10,
              message: '登录用户名不符合3 到 10 个字符',
              trigger: 'blur'
            },
            { validator: findUser , trigger: 'blur' }
          ],
          password: [{
              required: true,
              message: '请输入密码',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 60,
              message: '密码格式错误',
              trigger: 'blur'
            }
          ]
        },
        headTitle: {
          flag: false,
          x: 15,
          y: -420,
          position: ''
        },
        input: ''
      };
    },

    methods: {
      submitForm() {
        this.$refs.ruleForm.validate(async (valid) => {
          if (!valid) return
          const {
            data: result
          } = await this.$http.post('/user/login', this.ruleForm)

          if (result.status !== 200) return this.$message.error("用户名密码错误！")
          this.$message.success("用户登录成功！")
          // 设置网页session
          console.log(result.data)
          let token = result.data.token
          let ismanager = result.data.user.ismanager
          let userId = result.data.user.id
          window.sessionStorage.setItem('token', token)
          // 设置用户身份等级 管理层级，0==系统、高管 1==财务 2==人事 3==仓库
          window.sessionStorage.setItem('ismanager', ismanager)
          // 设置用户id，便于后期根据用户id查询相应消息
          window.sessionStorage.setItem('userId', userId)
          // 跳转到home
          this.pushHome()
        })
      },
      pushHome() {
        this.$router.push('/home')
      },
      resetForm() {
        this.$refs.ruleForm.resetFields();
      }
    },
  }
</script>

<style lang="less" scoped>
  .v-enter,
  .v-leave-to {
    opacity: 0;
    transform: translateX(100px);
  }

  /* v-enter-active 【入场动画的时间段】 */
  /* v-leave-active 【离场动画的时间段】 */
  .v-enter-active,
  .v-leave-active {
    transition: all 0.8s ease;
  }

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 1s;
  }

  #ad {
    position: absolute;
    transform: translate(0%, -100%);
    background-color: white;
    height: 120px;
    width: 100%;
  }

  .login-body {
    background-color: white;

    img {
      width: 100%;
    }
  }

  .demo-ruleForm {
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 90%;
    box-sizing: border-box;
  }

  #form {
    width: 450px;
    height: 360px;
    background-color: white;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  #control {
    position: absolute;
    transform: translate(0%, 0%);
    left: 50%;
    top: 50%;
    width: 500;
    height: 500;
  }

  .head-a {
    position: relative;
    width: 400;
    height: 300;
  }

  .head-b {
    position: relative;
    width: 400;
    height: 300;
  }

  .hand {}

  #title-head {
    position: absolute;
    transform: translate(-15%, -135%);
    width: 500px;
    height: 200px;
  }
</style>
