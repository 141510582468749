<template>
  <div id="">

    <transition name="fade">
      <div v-if="show" class="wrapper wrapper-content animated fadeInRight"
        style="background-color: white; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
        <div style="background-color: white;">
          <div style="margin-bottom: 10px;font-size: 18px;color: #3c8dbc; font-weight: 700;">
            <span>供应商管理</span>
          </div>
          <div id="">
            <el-input placeholder="供应商名称" class="input-with-select"
              style="width: 400px;margin-left: 10px; margin-right: 10px;" slot="append" v-model="pageInfo.query" @keyup.enter.native="getRequestList()">
              <el-button slot="append" icon="el-icon-search" type="success" @click="getRequestList()">搜索</el-button>
            </el-input>
            <el-button type="success" icon="el-icon-edit" plain @click="dialogFormVisible=true"
              style="position: absolute;right: 30px;">新增供应商</el-button>
          </div>

          <!-- 大表格 -->
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="供应商列表" name="1">
              <div class="block">
                <el-table :data="requestList" style="width: 100% " :row-class-name="tableRowClassName">
                  <el-table-column type="index" width="100" label="序号" align="center">
                  </el-table-column>
                  <el-table-column prop="name" label="供应商名称" align="center">
                  </el-table-column>
                  <el-table-column prop="contacts" label="联系人"  align="center">
                  </el-table-column>
                  <el-table-column prop="telephone" label="电话"  align="center">
                  </el-table-column>
                  <el-table-column prop="address" label="地址" align="center">
                  </el-table-column>
                  <el-table-column prop="email" label="邮箱" align="center">
                  </el-table-column>
                  <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                       <el-row style="font-size: 18px;">
                         <el-button type="primary" plain size="small" @click="showVacation(scope.row)"><i class="el-icon-tickets"> </i> 修改</el-button>
                         <el-button type="danger" plain size="small" @click="deleteRequst(scope.row.id);"><i class="el-icon-delete"> </i> 删除</el-button>
                       </el-row>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pageInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="pageInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="totalPage">
                </el-pagination>
              </div>
            </el-tab-pane>

          </el-tabs>
        </div>
      </div>
    </transition>
    <el-dialog title="新增供应商" :visible.sync="dialogFormVisible">
      <el-form ref="form" :model="newTrader" label-width="180px">
        <el-form-item label="供应商名称" style="width: 80%;">
          <el-input v-model="newTrader.name"></el-input>
        </el-form-item>
        <el-form-item label="联系人" style="width: 80%;">
          <el-input v-model="newTrader.contacts"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" style="width: 80%;">
          <el-input v-model="newTrader.phoneNum"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" style="width: 80%;">
          <el-input v-model="newTrader.email"></el-input>
        </el-form-item>
        <el-form-item label="供应商描述" style="width: 80%;">
          <el-input v-model="newTrader.description"></el-input>
        </el-form-item>
        <el-form-item label="公司电话" style="width: 80%;">
          <el-input v-model="newTrader.telephone"></el-input>
        </el-form-item>
        <el-form-item label="供应商地址" style="width: 80%;">
          <el-input v-model="newTrader.address"></el-input>
        </el-form-item>
        <el-form-item label="传真" style="width: 80%;">
          <el-input v-model="newTrader.taxNum"></el-input>
        </el-form-item>
        <el-form-item label="税率" style="width: 80%;">
          <el-input v-model="newTrader.taxRate" type="number"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" style="width: 80%;">
          <el-input v-model="newTrader.bankName"></el-input>
        </el-form-item>
        <el-form-item label="账号" style="width: 80%;">
          <el-input v-model="newTrader.accountNumber"></el-input>
        </el-form-item>
        <el-form-item style="text-align: center; width: 80%;">
          <el-button type="primary" @click="onSubmit">创建</el-button>
          <el-button @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


    <el-dialog title="供应商信息修改" :visible.sync="vacationVisible">
      <el-form ref="form" :model="trader" label-width="180px">
        <el-form-item label="供应商名称" style="width: 80%;">
          <el-input v-model="trader.name"></el-input>
        </el-form-item>
        <el-form-item label="联系人" style="width: 80%;">
          <el-input v-model="trader.contacts"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" style="width: 80%;">
          <el-input v-model="trader.phoneNum"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" style="width: 80%;">
          <el-input v-model="trader.email"></el-input>
        </el-form-item>
        <el-form-item label="供应商描述" style="width: 80%;">
          <el-input v-model="trader.description"></el-input>
        </el-form-item>
        <el-form-item label="公司电话" style="width: 80%;">
          <el-input v-model="trader.telephone"></el-input>
        </el-form-item>
        <el-form-item label="供应商地址" style="width: 80%;">
          <el-input v-model="trader.address"></el-input>
        </el-form-item>
        <el-form-item label="传真" style="width: 80%;">
          <el-input v-model="trader.taxNum"></el-input>
        </el-form-item>
        <el-form-item label="税率" style="width: 80%;">
          <el-input v-model="trader.taxRate" type="number"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" style="width: 80%;">
          <el-input v-model="trader.bankName"></el-input>
        </el-form-item>
        <el-form-item label="账号" style="width: 80%;">
          <el-input v-model="trader.accountNumber"></el-input>
        </el-form-item>
        <el-form-item style="text-align: center; width: 80%;">
          <el-button type="primary" @click="updateTrader">修改</el-button>
          <el-button @click="vacationVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="确定要删除？" :visible.sync="deleteMsgVisible">
      <div style="color: #0077AA;font-size: 20px;text-align: center;font-weight: 700;"><i class="el-icon-info"></i>此操作无法复原，是否继续删除？</div>
      <div style="text-align: center;width: 100%; margin-top: 20px;">
        <el-button @click="deleteMsgVisible = false" type="infor"><i class="el-icon-close"></i> 取 消
        </el-button>
        <el-button @click="doDeleteRequst" type="danger" plain><i class="el-icon-delete-solid"></i> 删 除
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.getRequestList()
      this.getUserInfo()
    },
    watch: {},
    methods: {
      //修改
      async updateTrader(){
        this.vacationVisible = false
        const {data: result} = await this.$http.put("/trader/update/",this.trader)
        if(result.status !== 200) return this.$message.error("删除失败！")
        this.$message.success("删除成功！")
        this.getRequestList()
      },
      //新增
      async onSubmit(){
        this.dialogFormVisible = false
        this.newTrader.type = "供应"
        const {data: result} = await this.$http.post("/trader/insertTrader/",this.newTrader)
        if(result.status !== 200) return this.$message.error("删除失败！")
        this.$message.success("删除成功！")
        this.getRequestList()
      },
      async doDeleteRequst(){
        this.deleteMsgVisible = false
        const {data: result} = await this.$http.delete("/trader/deleteTrader/"+this.deleteMsgId)
        if(result.status !== 200) return this.$message.error("删除失败！")
        this.$message.success("删除成功！")
        this.getRequestList()
      },
      deleteRequst(val){
        this.deleteMsgVisible = true
        this.deleteMsgId = val
      },
      async getRequestList(){
        let userId = window.sessionStorage.getItem('userId')
        this.pageInfo.start = "供应"
        const {data: result} = await this.$http.get('/trader/list',{
           params: this.pageInfo
        })
        if(result.status !== 200) return this.$message.error("供应商列表查询失败")
        this.requestList = result.data.rows
        this.totalPage = result.data.total
      },
      // 请假进度
      showVacation(request){
        this.vacationVisible = true
        this.trader = request
        console.log(request)
      },
      showVacation1(request){
        this.accVisible = true
        this.vacationObj.processors = request.processors
        console.log(request)
      },
      showVacation2(request){
        this.leaveVisible = true
        this.vacationObj.processors = request.processors
        console.log(request)
      },
      dialogClick(){
        this.UserRequests={
          userId: '',
          type: 1,
          status: 0,
          processors: '',
          startType: '',
          endType: '',
          remark: '',
          total: ''
        }
      },
      handleClick(tab, event) {
        this.pageInfo.type = this.activeName
        this.getRequestList()
            },
      tableRowClassName({row, rowIndex}) {
              if (rowIndex === 1) {
                return 'warning-row';
              } else if (rowIndex === 3) {
                return 'success-row';
              }
              return '';
            },
      async getUserInfo(){
        let userId = window.sessionStorage.getItem('userId')
        const {data: result} = await this.$http.get("/user/getById/"+userId)
        this.UserInfo = result.data
        console.log(this.UserInfo)
      },
            // 新增请求
      async sendRequests(){
        this.dialogFormVisible = false
        this.UserRequests.type = this.aplayActiveName
        let userId = window.sessionStorage.getItem('userId')
        this.UserRequests.userId = userId
        this.UserRequests.leaderId = this.UserInfo.leaderId
        this.UserRequests.processors = userId
        console.log(this.UserRequests)
        const {data: result} = await this.$http.post('/requests/add',this.UserRequests)

        if(result.status !== 200) return this.$message.error("申请发送失败！")
        this.$message.success("新增申请成功！")
        this.getRequestList()
      },
      // timeFormat(val){
      //   return this.moment(val).format("YYYY年MM月DD日")
      // },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val
        this.getRequestList()
        // console.log(this.pageInfo);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNum = val
        this.getRequestList()
        // console.log(`当前页: ${val}`);
      },
      open1() {
              this.$notify({
                title: '成功',
                message: '操作成功！',
                type: 'success'
              });
            },

            open2() {
              this.$notify({
                title: '警告',
                message: '请考虑后在操作！',
                type: 'warning'
              });
            },

            open3() {
              this.$notify.info({
                title: '消息',
                message: '这是一条消息的提示消息'
              });
            },

            open4() {
              this.$notify.error({
                title: '错误',
                message: '操作失败！'
              });
            }
    },
    data() {
      return {
        trader: '',
        newTrader: {
          accountNumber: "",
          address: "",
          bankName: "",
          contacts: "",
          description: "",
          email: "",
          name: "",
          phoneNum: "",
          taxNum: "",
          taxRate: 0.23,
          telephone: "",
          type: ""
        },
        UserInfo: '',
        deleteMsgId: '',
        deleteMsgVisible: false,
        accVisible: false,
        leaveVisible: false,
        requestList:{
          userId: '',
          type: 1,
          status: 0,
          processors: '',
          startType: '',
          remark: '',
          endType: '',
          total: ''
        },
        options: [{
                  value: '行程',
                  label: '行程'
                }, {
                  value: '餐饮',
                  label: '餐饮'
                }, {
                  value: '住宿',
                  label: '住宿'
                }, {
                  value: '医疗',
                  label: '医疗'
                }, {
                  value: '采购',
                  label: '采购'
                }],
        pageInfo: {
          userId: '',
          type: 1,
          query: '',
          pageNum: 1,
          pageSize: 5
        },
        vacationObj: {
          processors: [2,3]
        },
        vacationVisible: false,
        aplayActiveName: '1',
        activeName: '1',
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        UserRequests: {
          userId: '',
          leaderId: '',
          type: 1,
          status: '0',
          processors: '',
          startType: '',
          remark: '',
          endType: '',
          total: ''
        },
        formLabelWidth: '90px',
        dialogFormVisible: false,
        show: true,
        currentPage1: 5,
        currentPage2: 5,
        currentPage3: 5,
        totalPage: 4,
      };
    }
  };
</script>

<style scoped lang="less">
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }

  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
