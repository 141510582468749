<template>
  <div id="">
    <transition name="fade">
      <div v-if="show" class="wrapper wrapper-content animated fadeInRight"
        style="background-color: white; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
        <div style="background-color: white;">
          <div style="margin-bottom: 10px;font-size: 18px;color: #3c8dbc; font-weight: 700;">
            <span>未处理事项</span>
          </div>
          <div id="">
            <el-input placeholder="请输入内容" class="input-with-select"
              style="width: 400px;margin-left: 10px; margin-right: 10px;" slot="append" v-model="pageInfo.query"
              @keyup.enter.native="getRequestList()">
              <el-button slot="append" icon="el-icon-search" type="success" @click="getRequestList()">搜索</el-button>
            </el-input>
          </div>

          <!-- 大表格 -->
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="员工请假" name="1">
              <div class="block">
                <el-table :data="requestList" style="width: 100% " :row-class-name="tableRowClassName">
                  <el-table-column type="index" width="100" label="序号" align="center">
                  </el-table-column>
                  <el-table-column prop="startType" label="请假开始时间" align="center">
                  </el-table-column>
                  <el-table-column prop="endType" label="请假结束时间" align="center">
                  </el-table-column>
                  <el-table-column prop="total" label="请假时长" align="center">
                  </el-table-column>
                  <el-table-column prop="remark" label="请假原因" align="center">
                  </el-table-column>
                  <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                      <div id="">
                        <el-button size="small" plain type="warning" v-if="scope.row.status == 2 ? false : true"><i
                            class="el-icon-loading"></i> 进行中</el-button>
                        <el-button size="small" plain type="success" v-if="scope.row.status == 2 ? true : false"><i
                            class="el-icon-finished"></i> 已完成</el-button>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                      <el-row style="font-size: 18px;">
                        <el-button type="primary" plain size="small" @click="showVacation(scope.row)"><i
                            class="el-icon-tickets"> </i> 查看</el-button>
                        <el-button type="success" plain size="small" @click="applyRequst(scope.row);"><i
                            class="el-icon-edit-outline"> </i> 批准</el-button>
                      </el-row>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pageInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="pageInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="totalPage">
                </el-pagination>
              </div>
            </el-tab-pane>

            <el-tab-pane label="员工离职" name="3">
              <div class="block">
                <el-table :data="requestList" style="width: 100% " :row-class-name="tableRowClassName">
                  <el-table-column type="index" width="100" label="序号" align="center">
                  </el-table-column>
                  <el-table-column prop="startType" label="申请日期" width="300%" align="center">
                  </el-table-column>
                  <el-table-column prop="endType" label="离职日期" width="300%" align="center">
                  </el-table-column>
                  <el-table-column prop="remark" label="离职原因" align="center" width="360%">
                  </el-table-column>
                  <el-table-column prop="status" label="状态" align="center" width="220%">
                    <template slot-scope="scope">
                      <div id="">
                        <el-button size="small" plain type="warning" v-if="scope.row.status == 2 ? false : true"><i
                            class="el-icon-loading"></i> 进行中</el-button>
                        <el-button size="small" plain type="success" v-if="scope.row.status == 2 ? true : false"><i
                            class="el-icon-finished"></i> 已完成</el-button>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="操作" align="center" width="300%">
                    <template slot-scope="scope">
                      <el-row style="font-size: 18px;">
                        <el-button type="primary" plain size="small" @click="showVacation2(scope.row)"><i
                            class="el-icon-tickets"> </i> 查看</el-button>
                        <el-button type="success" plain size="small" @click="applyRequst(scope.row);"><i
                            class="el-icon-edit-outline"> </i> 批准</el-button>
                      </el-row>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="pageInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="pageInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="totalPage">
                </el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </transition>
    <el-dialog title="请假进度" :visible.sync="vacationVisible">
      <div style="width: 100%;">
        <el-steps :active="vacationObj.processors.length" align-center>
          <el-step title="发起申请" icon="el-icon-edit"></el-step>
          <el-step title="领导审批" icon="el-icon-user-solid"></el-step>
          <el-step title="人事审批" icon="el-icon-s-cooperation"></el-step>
        </el-steps>
      </div>
      <el-tabs v-model="aplayActiveName" @tab-click="dialogClick">
        <div style="text-align: center;width: 90%; margin-top: 20px;">
          <el-form :model="UserRequests">
            <el-form-item label="" label-width="120px" style="text-align: center;width: 96%;">
              <el-button @click="vacationVisible = false" type="success" plain><i class="el-icon-check"></i> 确 定
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tabs>
    </el-dialog>
    <el-dialog title="离职进度" :visible.sync="leaveVisible">
      <div style="width: 100%;">
        <el-steps :active="vacationObj.processors.length" align-center>
          <el-step title="发起申请" icon="el-icon-edit"></el-step>
          <el-step title="领导审批" icon="el-icon-user-solid"></el-step>
          <el-step title="人事审批" icon="el-icon-s-cooperation"></el-step>
        </el-steps>
      </div>
      <el-tabs v-model="aplayActiveName" @tab-click="dialogClick">
        <div style="text-align: center;width: 90%; margin-top: 20px;">
          <el-form :model="UserRequests">
            <el-form-item label="" label-width="120px" style="text-align: center;width: 96%;">
              <el-button @click="leaveVisible = false" type="success" plain><i class="el-icon-check"></i> 确 定
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tabs>
    </el-dialog>
    <el-dialog title="财务报销进度" :visible.sync="accVisible">
      <div style="width: 100%;">
        <el-steps :active="vacationObj.processors.length" align-center>
          <el-step title="发起申请" icon="el-icon-edit"></el-step>
          <el-step title="领导审批" icon="el-icon-user-solid"></el-step>
          <el-step title="财务审批" icon="el-icon-coin"></el-step>
        </el-steps>
      </div>
      <el-tabs v-model="aplayActiveName" @tab-click="dialogClick">
        <div style="text-align: center;width: 90%; margin-top: 20px;">
          <el-form :model="UserRequests">
            <el-form-item label="" label-width="120px" style="text-align: center;width: 96%;">
              <el-button @click="accVisible = false" type="success" plain><i class="el-icon-check"></i> 确 定
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tabs>
    </el-dialog>
    <el-dialog title="批准用户申请" :visible.sync="updateMsgVisible">
      <el-form :model="request" label-width="240px" style="text-align: center;">
        <el-form-item label="申请人姓名:">
          <div v-text="request.userId" style="text-align: center;width: 70%;"></div>
        </el-form-item>
        <el-form-item label="类型:">
          <div v-text="request.type" style="text-align: center;width: 70%;"></div>
        </el-form-item>
        <el-form-item label="备注:">
          <div v-text="request.remark" style="text-align: center;width: 70%;"></div>
        </el-form-item>
        <el-form-item>
          <div style="text-align: center;width: 70%;">
            <el-button @click="updateMsgVisible = false" type="infor"><i class="el-icon-close"></i> 取 消
            </el-button>
            <el-button @click="doApplyRequst" type="success" plain><i class="el-icon-delete-solid"></i> 同 意
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created() {
      this.getUserInfo()
    },
    mounted() {
      this.getRequestList()
    },
    watch: {
      'UserRequests.startType'(val) {
        if (this.UserRequests.endType & val) {
          var dateBegin = new Date(this.UserRequests.endType);
          var dateEnd = new Date(val);
          var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
          var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) + 1; //计算出相差天数
          this.UserRequests.total = dayDiff + "天"
        } else {
          this.UserRequests.total = ''
        }

      },
      'UserRequests.endType'(val) {
        if (this.UserRequests.startType & val) {
          var dateEnd = new Date(val);
          var dateBegin = new Date(this.UserRequests.startType);
          var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
          var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) + 1; //计算出相差天数
          this.UserRequests.total = dayDiff + "天"
        } else {
          this.UserRequests.total = ''
        }
      }
    },
    methods: {
      async doApplyRequst() {
        this.updateMsgVisible = false
        this.updateMsg.processors = this.updateMsg.processors[0]+','+this.updateMsg.processors[1]+','+this.UserInfo.id
        console.log(this.updateMsg)
        const {
          data: result
        } = await this.$http.put("/requests/agree/",this.updateMsg)
        if (result.status !== 200) return this.$message.error("批准失败！")
        this.$message.success("批准成功！")
        this.getRequestList()
      },
      async getUserInfo() {
        let userId = window.sessionStorage.getItem('userId')
        const {
          data: result
        } = await this.$http.get("/user/getById/" + userId)
        this.UserInfo = result.data
        console.log(this.UserInfo)
      },
      async applyRequst(val) {
        this.updateMsgVisible = true
        this.updateMsg = Object.assign({}, val)
        console.log(this.updateMsg)
        var id = val.userId
        if (val.type == 1) {
          val.type = '请假申请'
        } else if (val.type == 2) {
          val.type = '费用报销申请'
        } else if (val.type == 3) {
          val.type = '离职申请'
        } else {}
        const {
          data: result
        } = await this.$http.get("/user/getById/" + id)
        this.request = Object.assign({}, val)
        this.request.userId = result.data.username
        val = ''
      },
      async getRequestList() {
        let userId = window.sessionStorage.getItem('userId')
        this.pageInfo.userId = userId
        const {
          data: result
        } = await this.$http.get('/requests/done', {
          params: this.pageInfo
        })
        if (result.status !== 200) return this.$message.error("申请单列表查询失败")
        // this.$message.success("查询成功！")
        let arr = result.data.rows
        for (let item of arr) {
          let proceArr = item.processors.split(",")
          item.processors = proceArr
        }
        this.requestList = arr
        this.totalPage = result.data.total
        console.log(this.requestList)
      },
      showVacation(request) {
        this.vacationVisible = true
        this.vacationObj.processors = request.processors
        console.log(request)
      },
      showVacation1(request) {
        this.accVisible = true
        this.vacationObj.processors = request.processors
        console.log(request)
      },
      showVacation2(request) {
        this.leaveVisible = true
        this.vacationObj.processors = request.processors
        console.log(request)
      },
      dialogClick() {
        this.UserRequests = {
          userId: '',
          type: 1,
          status: false,
          processors: '',
          startType: '',
          endType: '',
          remark: '',
          total: ''
        }
      },
      handleClick(tab, event) {
        this.pageInfo.type = this.activeName
        this.getRequestList()
      },
      tableRowClassName({
        row,
        rowIndex
      }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },

      handleSizeChange(val) {
        this.pageInfo.pageSize = val
        this.getRequestList()
        // console.log(this.pageInfo);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNum = val
        this.getRequestList()
        // console.log(`当前页: ${val}`);
      },
      open1() {
        this.$notify({
          title: '成功',
          message: '操作成功！',
          type: 'success'
        });
      },

      open2() {
        this.$notify({
          title: '警告',
          message: '请考虑后在操作！',
          type: 'warning'
        });
      },

      open3() {
        this.$notify.info({
          title: '消息',
          message: '这是一条消息的提示消息'
        });
      },

      open4() {
        this.$notify.error({
          title: '错误',
          message: '操作失败！'
        });
      }
    },
    data() {
      return {
        updateMsg: '',
        UserInfo: '',
        updateMsgVisible: false,
        accVisible: false,
        leaveVisible: false,
        request: '',
        requestList: {
          userId: '',
          type: 1,
          status: false,
          processors: '',
          startType: '',
          remark: '',
          endType: '',
          total: ''
        },
        pageInfo: {
          userId: '',
          type: 1,
          query: '',
          pageNum: 1,
          pageSize: 5
        },
        vacationObj: {
          processors: [2, 3]
        },
        vacationVisible: false,
        aplayActiveName: '1',
        activeName: '1',
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        UserRequests: {
          userId: '',
          type: 1,
          status: false,
          processors: '',
          startType: '',
          remark: '',
          endType: '',
          total: ''
        },
        formLabelWidth: '90px',
        dialogFormVisible: false,
        show: true,
        currentPage1: 5,
        currentPage2: 5,
        currentPage3: 5,
        totalPage: 4,
      };
    }
  };
</script>

<style scoped lang="less">
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }

  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
