<template>
  <div class="hello">
    <transition>
      <h3 v-if="flag">
        <div id="app">
          <input type="button" value="toggle" @click="flagCh">
          <!-- 需求： 点击按钮，让 h3 显示，再点击，让 h3 隐藏 -->
          <!-- 1. 使用 transition 元素，把 需要被动画控制的元素，包裹起来 -->
          <!-- transition 元素，是 Vue 官方提供的 -->
          <div id="root">
            <transition name="fade">
              <div v-if="show">hello world</div>
            </transition>
            <button @click="handleClick">切换</button>
          </div>
        </div>
      </h3>
    </transition>
  </div>
</template>

<script>
  export default {
    created() {
      setTimeout(() => {
        this.flag = true
      }, 100);
    },
    data() {
      return {
        show: true,
        flag: false
      }
    },
    name: 'HelloWorld',
    methods: {
      handleClick() {
        this.show = !this.show;
      },
      flagCh() {
        setTimeout(() => {
          this.flag = !this.flag
        }, 1000);
      }
    },
    props: {
      msg: String
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 3s;
  }

  .fade-leave-to {
    opacity: 0;
  }

  .fade-leave-active {
    transition: opacity 3s;
  }

  .v-enter,
  .v-leave-to {
    opacity: 0;
    transform: translateX(50px);
  }

  /* v-enter-active 【入场动画的时间段】 */
  /* v-leave-active 【离场动画的时间段】 */
  .v-enter-active,
  .v-leave-active {
    transition: all 0.8s ease;
  }

  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
