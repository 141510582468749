<template>
  <div class="app-container">

    <el-dialog title="修改头像" :visible.sync="dialogVisible" width="600px">
      <div style="margin-left: 18%;">
        <el-upload  class="upload-demo"
        style="width: 360px;"
        :action="uploadUrl"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
          :on-success="handleSuccess"
          list-type="picture" multiple drag>
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
        <div style="width: 300px;margin-left: 25%;margin-top: 18px;">
          <el-button type="primary" size="mini" @click="saveHead()">保存</el-button>
          <el-button type="danger" size="mini" @click="dialogVisible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>

    <el-row :gutter="20">
      <el-col :span="7" :xs="30">
        <div style="height: 50px;background-color: white;">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>个人信息</span>
            </div>
            <div class="box-shadow head-img"
              style="width: 160px; height: 160px;border: #000000; margin: 20px;margin-bottom: 30px;margin-left: 33%;overflow: hidden;"
              @click="dialogVisible = true">
              <img :src="user.imgPath" style="width: 160px;border: #000000;" />
            </div>
            <div>
              <el-form :model="user">
                <el-form-item label="请假结束" :label-width="formLabelWidth">
                  <div style="text-align: center;" v-text="user.username">
                  </div>
                </el-form-item>
                <el-form-item label="请假天数" :label-width="formLabelWidth">
                  <div style="text-align: center;" v-text="user.phone">
                  </div>
                </el-form-item>
                <el-form-item label="请假原因" :label-width="formLabelWidth">
                  <div style="text-align: center;" v-text="user.email">
                  </div>
                </el-form-item>
                <el-form-item label="请假原因" :label-width="formLabelWidth">
                  <div style="text-align: center;" v-text="user.position">
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </div>
      </el-col>

      <el-col :span="15" :xs="24">
        <el-card>
          <!-- slot—插槽，可以将自定义的元素放入到分页容器中，每一个元素标签都需要一个slot进行占位及插入到分页的容器中 -->
          <div slot="header" class="clearfix">
            <span>基本资料</span>
          </div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基本资料" name="userinfo">
              <el-form :model="resetUserModel" :rules="rules" ref='resetUserRef' label-width="80px"
                :visible.sync="dialogVisible">
                <el-form-item label="用户昵称">
                  <el-input v-model="resetUserModel.username" disabled></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                  <el-input v-model="resetUserModel.phone" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <el-input v-model="resetUserModel.email" maxlength="50"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                  <template>
                    <el-radio :label="false" v-model="resetUserModel.sex">男</el-radio>
                    <el-radio :label="true" v-model="resetUserModel.sex">女</el-radio>
                  </template>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="mini" @click="usersubmit()">保存</el-button>
                  <el-button type="danger" size="mini" @click="userclose()">取消</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>

            <el-tab-pane label="修改密码" name="resetPwd">

              <el-form :model="resetPasswordModel" status-icon :rules="rules" ref="resetPasswordModel"
                label-width="80px" class="demo-ruleForm">
                <el-form-item label="密码" prop="oldpass">
                  <el-input type="password" v-model="resetPasswordModel.oldpass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="pass">
                  <el-input type="password" v-model="resetPasswordModel.pass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                  <el-input type="password" v-model="resetPasswordModel.checkPass" autocomplete="off"></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="submitForm()">提交</el-button>
                  <el-button @click="resetForm('resetPasswordModel')">重置</el-button>
                </el-form-item>
              </el-form>

            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="图片预览" :visible.sync="dialogImageVisible" width="550px">
      <div style="width: 400px;height: 400px;margin-left: 60px;margin-bottom: 40px;">
        <img :src="imageUrlPath" width="400px" height="400px" />
      </div>
    </el-dialog>
  </div>
</template>


<script>
  export default {
    data() {
      //校验邮箱规则 rule校验规则   value校验的数据   callback回调函数
      const checkEmail = (rule, value, callback) => {
        //定义邮箱的正则表达式  JS中用/来表示正则表达式的开始和结束
        const emailRege = /^[a-zA-Z0-9-_]+@[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+$/
        //正则表达式语法校验  test(xx) 校验成功 返回true  校验失败返回false
        if (emailRege.test(value)) {
          //表示邮箱合法 正确返回
          return callback()
        }
        callback(new Error('请填写正确的邮箱地址'))
      };

      //校验手机号的邮箱规则
      const checkPhone = (rule, value, callback) => {
        //定义校验手机号的正则语1
        const phoneRege = /^1[34578][0-9]{9}$/
        if (phoneRege.test(value)) {
          return callback()
        }
        callback(new Error('请填写正确的手机号'))
      };

      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.resetPasswordModel.checkPass !== '') {
            this.$refs.resetPasswordModel.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.resetPasswordModel.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };

      var validatePass3 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入旧密码'));
        } else {
          callback();
        }
      };

      return {
        hideUpload: false,
        dialogImageVisible: false,
        imageUrlPath: '',
        uploadUrl: this.$http.defaults.baseURL+"/file/upload",
        formLabelWidth: "140px",
        activeName: "userinfo",
        dialogVisible: false,
        user: '',
        resetUserModel: {
          id: '',
          username: '',
          phone: '',
          email: '',
          sex: ''
        },
        resetPasswordModel: {
          id: '',
          oldpass: '',
          pass: '',
          checkPass: '',
        },
        rules: {
          pass: [{
            validator: validatePass,
            trigger: 'blur'
          }],
          checkPass: [{
            validator: validatePass2,
            trigger: 'blur'
          }],
          oldpass: [{
            validator: validatePass3,
            trigger: 'blur'
          }],
          phone: [{
              required: true,
              message: '请输入手机号',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 30,
              message: '长度在 3 到 30 个字符',
              trigger: 'blur'
            },
            {
              validator: checkPhone,
              trigger: 'blur'
            }
          ],
          email: [{
              required: true,
              message: '请输入邮箱',
              trigger: 'blur'
            },
            {
              min: 3,
              max: 30,
              message: '长度在 3 到 30 个字符',
              trigger: 'blur'
            },
            {
              validator: checkEmail,
              trigger: 'blur'
            }
          ]
        }
      }
    },
    methods: {
      async saveHead(){
        this.dialogVisible = false
        console.log(this.user)
        const {
        data: result
          } = await this.$http.post('/user/resetUser', this.user)
      if (result.status !== 200) return this.$message.error("头像上传失败")
      this.$message.success("头像上传成功")
      },
       handleSuccess(response, file) {
              if (response.status !== 200) return this.$message.error("文件上传失败")
              file.name = response.data.fileName
              //获取虚拟路径
              let virtualPath = response.data.urlPath
              //将数据封装到Form表单中
              this.user.imgPath = virtualPath
              console.log(virtualPath)
            },

      async handleRemove(file) {

              //移除数组中的数据
              let virtualPath = file.response.data.virtualPath
              //通过findIndex函数 获取数组中指定数据的位置
              let index = this.addItemForm.images.findIndex(x => x === virtualPath)
              //删除数组中指定的数据
              this.addItemForm.images.splice(index, 1)
              //删除服务中的文件
              let {
                data: result
              } = await this.$http.delete("/file/deleteFile", {
                params: {
                  virtualPath: virtualPath
                }
              })
              if (result.status !== 200) return this.$message.error("删除图片失败")
              this.$message.success("删除图片成功")
            },

      handlePreview(file) {
              //获取图片的虚拟路径
              this.imageUrlPath = file.response.data.urlPath
      		console.log(file.response.data.virtualPath)
      		console.log(this.imageUrlPath)
              this.dialogImageVisible = true
            },

      handleClick(tab, event) {
        console.log(tab, event);
      },
      userclose() {
        //刷新页面
        this.getUser()
      },

      async updateUserBtn(user) {
        this.updateDialogVisible = true
        const {
          data: result
        } = await this.$http.get("/user/" + user.id)
        if (result.status !== 200) return this.$message.error("用户查询失败")
        this.updateUserModel = result.data
      },

      usersubmit() {
        this.$refs.resetUserRef.validate(async valid => {
          //如果校验失败 则停止数据
          if (!valid) return
          var Id = window.sessionStorage.getItem("userId")

          this.resetUserModel.id = Id
          console.log(this.resetUserModel.userId)
          const {
            data: result
          } = await this.$http.post('/user/resetUser', this.resetUserModel)
          if (result.status !== 200) return this.$message.error("修改失败")
          this.$message.success("修改成功")
          console.log(this.resetUserModel)
          //重新获取用户列表
          this.getUser()
        })
      },

      submitForm() {
        this.$refs.resetPasswordModel.validate(async valid => {
          //如果校验失败 则停止数据
          if (!valid) return
          //console.log(this.addUserModel)
          var Id = window.sessionStorage.getItem("userId")
          this.resetPasswordModel.id = Id
          const {
            data: result
          } = await this.$http.put('/user/resetPassword', this.resetPasswordModel)
          if (result.status !== 200) return this.$message.error("oldPassword is false  修改密码失败")
          this.$message.success("修改密码成功")
          resetForm(formName)
        })
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();

      },
      async getUser() {
        var id = window.sessionStorage.getItem("userId")
        const {
          data: result
        } = await this.$http.get('/user/getById/' + id)
        if (result.status !== 200) return this.$message.error("查询失败")
        this.user = result.data
        this.resetUserModel.username = result.data.username
        this.resetUserModel.phone = result.data.phone
        this.resetUserModel.email = result.data.email
        this.resetUserModel.sex = result.data.sex
        console.log(this.user)
      }
    },

    //利用钩子函数实现数据查询
    mounted() {
      this.getUser()
    }

  }
</script>



<style scoped>
  .el-steps {
    margin: 20px 0;
  }

  .el-cascader {
    width: 30%;
  }

  /* 控制复选框的右边距5像素 !important 表示优先执行 IE6不兼容*/
  .el-checkbox {
    margin: 0 10px 0 0 !important;
  }

  .addItemBtnClass {

    margin-top: 15px;
  }

  .box-shadow {
    box-shadow: 0px 0px 7px black;
  }

  .head-img {
    background-color: white;
    border-radius: 80px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
