import { render, staticRenderFns } from "./Trader.vue?vue&type=template&id=cfa7a218&scoped=true&"
import script from "./Trader.vue?vue&type=script&lang=js&"
export * from "./Trader.vue?vue&type=script&lang=js&"
import style0 from "./Trader.vue?vue&type=style&index=0&id=cfa7a218&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfa7a218",
  null
  
)

export default component.exports