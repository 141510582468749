<template>
  <div class="hello">
    <div style="width: 100%; height: 300px" ref="chart"></div>
    <transition>
      <h3 v-if="flag">
        <div id="app">
          <h1>测试</h1>
          <!-- <div style="width: 100%; height: 100%" ref="chart"></div> -->
        </div>
      </h3>
    </transition>
  </div>
</template>

<script>
  // let echarts = require('echarts/lib/echarts');
  // // 引入柱状图组件
  // require('echarts/lib/chart/bar');
  // 引入提示框和title组件
  // require('echarts/lib/component/tooltip');
  // require('echarts/lib/component/title');
  export default {
    mounted() {
      this.init()
      setTimeout(() => {
        this.flag = true
      }, 100);
    },
    data() {
      return {
        chart: null,
        option: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          toolbox: {
            feature: {
              dataView: {
                show: true,
                readOnly: false
              },
              magicType: {
                show: true,
                type: ['line', 'bar']
              },
              restore: {
                show: true
              },
              saveAsImage: {
                show: true
              }
            }
          },
          legend: {
            data: ['订单量', '入库量', '月收益']
          },
          xAxis: [{
            type: 'category',
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
            axisPointer: {
              type: 'shadow'
            }
          }],
          yAxis: [{
              type: 'value',
              name: '订单数',
              min: 0,
              max: 250,
              interval: 50,
              axisLabel: {
                formatter: '{value} 单'
              }
            },
            {
              type: 'value',
              name: '收益',
              min: 0,
              max: 50,
              interval: 10,
              axisLabel: {
                formatter: '{value} 元'
              }
            }
          ],
          series: [{
              name: '订单量',
              type: 'bar',
              data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
              color: 'rgb(0, 170, 0)'
            },
            {
              name: '入库量',
              type: 'bar',
              data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3],
              color: 'rgb(255, 170, 0)'
            },
            {
              name: '月收益',
              type: 'line',
              yAxisIndex: 1,
              data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
              color: 'rgb(85, 85, 255)'
            }
          ]
        }
      }
    },
    methods: {
      init() {
        //2.初始化
        this.chart = this.$echarts.init(this.$refs.chart);
        //3.配置数据
        // 4.传入数据
        this.chart.setOption(this.option);
      }
    },
    name: 'HelloWorld',
    props: {
      msg: String,
      flag: false
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .v-enter,
  .v-leave-to {
    opacity: 0;
    transform: translateX(50px);
  }

  /* v-enter-active 【入场动画的时间段】 */
  /* v-leave-active 【离场动画的时间段】 */
  .v-enter-active,
  .v-leave-active {
    transition: all 0.8s ease;
  }

  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
