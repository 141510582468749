<template>
  <div id="">
    <el-dialog title="创建仓库" :visible.sync="dialogFormVisible">
          <div style="text-align: center;width: 90%;">
            <el-form :model="Repository">
              <el-form-item label="仓库地址" :label-width="formLabelWidth">
                <el-input placeholder="请输入内容" v-model="Repository.address" style="width: 220px;" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="仓库名称" :label-width="formLabelWidth">
                <el-input placeholder="请输入内容" v-model="Repository.name" style="width: 220px;" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="仓库类型" :label-width="formLabelWidth">
                <el-select v-model="Repository.type" clearable placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="" label-width="120px" style="text-align: center;width: 96%;">
                <el-button @click="dialogFormVisible = false" type="info" plain><i class="el-icon-close"></i> 取 消
                </el-button>
                <el-button @click="newResp" type="success" plain><i class="el-icon-check"></i> 提 交
                </el-button>
              </el-form-item>
            </el-form>
          </div>
    </el-dialog>

    <el-dialog title="编辑仓库" :visible.sync="dialogFormVisible2">
          <div style="text-align: center;width: 90%;">
            <el-form :model="RepositoryEdit">
              <el-form-item label="仓库地址" :label-width="formLabelWidth">
                <el-input placeholder="请输入内容" v-model="RepositoryEdit.address" style="width: 220px;" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="仓库名称" :label-width="formLabelWidth">
                <el-input placeholder="请输入内容" v-model="RepositoryEdit.name" style="width: 220px;" clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="仓库类型" :label-width="formLabelWidth">
                <el-select v-model="RepositoryEdit.type" clearable placeholder="请选择">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="" label-width="120px" style="text-align: center;width: 96%;">
                <el-button @click="dialogFormVisible2 = false" type="info" plain><i class="el-icon-close"></i> 取 消
                </el-button>
                <el-button @click="updateRepos" type="success" plain><i class="el-icon-check"></i> 提 交
                </el-button>
              </el-form-item>
            </el-form>
          </div>
    </el-dialog>

    <transition name="fade">
      <div>
        <el-col :span="8">
          <div v-if="show" class="wrapper wrapper-content animated fadeInRight"
            style="height: 100%; background-color: white; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
            <div>
              <div style="margin-bottom: 10px;font-size: 18px;color: #3c8dbc; font-weight: 700;">
                <span>仓库信息</span>
              </div>
              <div id="">
                <el-input placeholder="请输入仓库名称" class="input-with-select"
                  style="width: 75%;margin-left: 10px; margin-right: 10px;" slot="append" v-model="pageInfo.query"
                  @keyup.enter.native="getResptList()">
                  <el-button slot="append" icon="el-icon-search" type="success" @click="getResptList()">搜索</el-button>
                </el-input>
                <el-button type="success" icon="el-icon-edit" plain @click="dialogFormVisible=true"
                  style="position: absolute;right: 30px;">创建</el-button>
              </div>

              <!-- 仓库表 -->
              <el-tabs v-model="activeName1">
                <el-tab-pane label="仓库列表" name="1">
                  <div class="block">
                    <el-table :data="ReposList" style="width: 100% " :row-class-name="tableRowClassName"
                      @row-click="rowClick">
                      <el-table-column type="index" width="100" label="序号" align="center">
                      </el-table-column>
                      <el-table-column prop="name" label="仓库名称" align="center">
                      </el-table-column>
                      <el-table-column label="编辑" align="center" width="120">
                        <template slot-scope="scope">
                          <el-row style="font-size: 18px;">
                            <el-button type="primary" icon="el-icon-edit" @click.stop="editRepos(scope.row)" circle>
                            </el-button>
                            <el-button type="danger" icon="el-icon-delete" @click.stop="deleteRepos(scope.row)" circle>
                            </el-button>
                          </el-row>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </el-col>

        <el-col :span="16">
          <div v-if="show2" class="wrapper wrapper-content animated fadeInRight"
            style="margin-left: 20px;  height: 100%; background-color: white; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
            <div>
              <div style="margin-bottom: 10px;font-size: 18px;color: #3c8dbc; font-weight: 700;">
                <div class="col-sm-10">
                  <span>库存信息</span>
                </div>
                <div class="col-sm-1" >
                  <div style="position: absolute; width: 20px;height: 20px; right: -80px;top: -26px;">
                    <el-button type="text" style="right: 0px; font-size: 24px;" @click="show2 = false"><i class="el-icon-close"></i></el-button>
                  </div>
                </div>
              </div> <br>

              <div id="">
                  <div class="block">
                    <el-table :data="ProductsInfo" style="width: 100% " :row-class-name="tableRowClassName">
                      <el-table-column type="index" width="100" label="序号" align="center">
                      </el-table-column>
                      <el-table-column prop="updated" label="货品名称" align="center">
                      </el-table-column>
                      <el-table-column prop="currentNumber" label="当前库存" :formatter="setUnit" width="180" align="center">
                      </el-table-column>
                      <el-table-column  label="库存状况" align="center" width="180">
                        <template slot-scope="scope">
                          <div id="">
                            <el-button size="small" plain type="danger" v-if="scope.row.currentNumber > 12 ? false : true"><i
                                class="el-icon-loading"></i> 库存紧缺</el-button>
                                <el-button size="small" plain type="warning" v-if="scope.row.currentNumber >= 12  && scope.row.currentNumber < 36 ? true : false"><i
                                    class="el-icon-loading"></i> 库存预警</el-button>
                            <el-button size="small" plain type="success" v-if="scope.row.currentNumber >= 36 ? true : false"><i
                                class="el-icon-finished"></i> 库存充足</el-button>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column label="状态" align="center" width="180">
                        <template slot-scope="scope">
                           <el-switch v-model="!scope.row.deleteFlag" @change="updateStatus(scope.row)"
                             active-color="#13ce66" inactive-color="#ff4949">
                           </el-switch>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                      :current-page="pageInfo.pageNum" :page-sizes="[5, 10, 15]" :page-size="pageInfo.pageSize"
                      layout="total, sizes, prev, pager, next, jumper" :total="totalPage">
                    </el-pagination>
                  </div>
              </div>
              <!-- 库存表 -->
            </div>
          </div>
        </el-col>
      </div>
    </transition>

    <el-dialog title="离职进度" :visible.sync="leaveVisible">
      <div style="width: 100%;">
      </div>
      <el-tabs v-model="aplayActiveName" @tab-click="dialogClick">
        <div style="text-align: center;width: 90%; margin-top: 20px;">
          <el-form :model="UserRequests">
            <el-form-item label="" label-width="120px" style="text-align: center;width: 96%;">
              <el-button @click="leaveVisible = false" type="success" plain><i class="el-icon-check"></i> 确 定
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tabs>
    </el-dialog>

    <el-dialog title="确定要删除？" :visible.sync="deleteMsgVisible">
      <div style="color: #0077AA;font-size: 20px;text-align: center;font-weight: 700;"><i
          class="el-icon-info"></i>此操作无法复原，是否继续删除？</div>
      <div style="text-align: center;width: 100%; margin-top: 20px;">
        <el-button @click="deleteMsgVisible = false" type="infor"><i class="el-icon-close"></i> 取 消
        </el-button>
        <el-button @click="dodeleteRepos" type="danger" plain><i class="el-icon-delete-solid"></i> 删 除
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.getResptList()
      this.getProducts()
    },
    watch: {},
    methods: {
      //绑定编辑仓库信息
      async updateRepos(){
        this.deleteMsgVisible2 = false
        const {data: result} = await this.$http.put("/repository/updateRepos/"+this.RepositoryEdit)
        if (result.status !== 200) return this.$message.error("仓库更新失败！")
        this.$message.success("仓库更新成功！")
        this.getResptList()
      },
      async dodeleteRepos(){
        this.deleteMsgVisible = false
        const {data: result} = await this.$http.delete("/repository/delete/"+this.deleteId)
        if (result.status !== 200) return this.$message.error("仓库删除失败！")
        this.$message.success("仓库删除成功！")
        this.getResptList()
      },
      deleteRepos(val){
        this.deleteMsgVisible = true
        this.deleteId = val.id
        console.log(this.deleteId)
      },
      async getProducts() {
        const {
          data: result
        } = await this.$http.get("/products/allList")
        if (result.status !== 200) return this.$message.error("产品列表查询失败")
        this.Products = result.data
      },
      async updateStatus(val){
        const {data: result} = await this.$http.get("/stock/update/"+val.id)
        if (result.status !== 200) return this.$message.error("产品状态修改失败 ~")
        this.$message.success("产品状态修改成功！")
        this.getProductList()
      },
      async newResp(){
        let userId = window.sessionStorage.getItem('userId')
        this.Repository.managerId = userId
        const {data: result} = await this.$http.post("/repository/newRepos",this.Repository)
        if (result.status !== 200) return this.$message.error("仓库创建失败 ~")
        this.$message.success("仓库创建成功！")
        this.getResptList()
      },
      editRepos(val) {
        this.dialogFormVisible2 = true
        this.RepositoryEdit = val
      },
      //选中仓库的触发事件
      setUnit(value){
        console.log(value)
        var id = value.materialId
        var dan
        for(var val of this.Products){
          if(val.id == id)
          dan = val.unit
        }
        return value.currentNumber + dan
      },
      setName(){
        console.log(this.ProductsInfo)
        console.log(this.Products)
        for(var item of this.ProductsInfo){
          var id = item.materialId
          for(var val of this.Products){
            if(val.id == id)
            item.updated = val.name
          }
        }
      },
      rowClick(row, column, event) {
        this.show2 = true
        this.pageInfo.type = row.id
        this.getProductList()
        console.log(row.id)
      },
      async getProductList() {
        const {
          data: result
        } = await this.$http.get('/stock/list/',{params: this.pageInfo})
        if (result.status !== 200) return this.$message.error("仓库列表查询失败")
        this.ProductsInfo = result.data
        this.setName()
      },
      async getResptList() {
        const {
          data: result
        } = await this.$http.get('/repository/list')
        if (result.status !== 200) return this.$message.error("仓库列表查询失败")
        this.ReposList = result.data
      },
      // 请假进度
      handleClick(tab, event) {
        this.pageInfo.type = this.activeName
        this.getRequestList()
      },
      tableRowClassName({
        row,
        rowIndex
      }) {
        if (rowIndex === 1) {
          return 'warning-row';
        } else if (rowIndex === 3) {
          return 'success-row';
        }
        return '';
      },
      async getUserInfo() {
        let userId = window.sessionStorage.getItem('userId')
        const {
          data: result
        } = await this.$http.get("/user/getById/" + userId)
        this.UserInfo = result.data
        console.log(this.UserInfo)
      },
      // 新增请求
      handleSizeChange(val) {
        this.pageInfo.pageSize = val
        this.getRequestList()
        // console.log(this.pageInfo);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNum = val
        this.getRequestList()
        // console.log(`当前页: ${val}`);
      },
    },
    data() {
      return {
        deleteId: '',
        options: [{
                  value: '家电产品',
                  label: '家电产品'
                }, {
                  value: '商用电器',
                  label: '商用电器'
                }],
        reposId: '',
        //库存表
        Products: '',
        //库存信息表
        ProductsInfo: '',
        // 仓库列表
        RepositoryEdit: '',
        Repository: {
          managerId: '',
          name: '',
          address: '',
          type: '',
          managerId: ''
        },
        ReposList: '',
        activeName1: '1',
        UserInfo: '',
        deleteMsgId: '',
        deleteMsgVisible: false,
        accVisible: false,
        leaveVisible: false,

        pageInfo: {
          userId: '',
          type: 1,
          query: '',
          pageNum: 1,
          pageSize: 5
        },

        vacationVisible: false,
        aplayActiveName: '1',
        activeName: '1',


        formLabelWidth: '90px',
        dialogFormVisible: false,
        dialogFormVisible2: false,
        show: true,
        show2: false,
        currentPage1: 5,
        currentPage2: 5,
        currentPage3: 5,
        totalPage: 4,
      };
    }
  };
</script>

<style scoped lang="less">
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }

  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
