<template>
  <div id="">
    <transition name="fade">
      <div v-if="show"
           class="wrapper wrapper-content animated fadeInRight"
           style="background-color: white; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
        <div style="background-color: white;">
          <div style="margin-bottom: 10px;font-size: 18px;color: #3c8dbc; font-weight: 700;">
            <span>订单管理</span>
            <div>
              <div style="margin-bottom: 6px;">
                <el-col :span="3">
                  <el-radio-group v-model="type"
                                  v-if="activeName == '1' ? true : false"
                                  fill="#67c03a"
                                  text-color="#ffffff"
                                  @change="getOrderList()">
                    <el-radio-button label="出库"></el-radio-button>
                    <el-radio-button label="入库"></el-radio-button>
                  </el-radio-group>
                </el-col>

                <el-col :span="3">
                  <div style="margin-top: 10px;"
                       v-if="activeName == '1' ? true : false">
                    <el-switch style="display: block"
                               v-model="status"
                               active-color="#13ce66"
                               @change="getList()"
                               inactive-color="#ff4949"
                               active-text="已审批"
                               inactive-text="未审批">
                    </el-switch>
                  </div>
                </el-col>
              </div>
              <div style="width: 100%;">
                <el-col :span="2">
                  <el-date-picker type="date"
                                  placeholder="起始日期"
                                  v-model="pageInfo.start"
                                  style="width: 140px;">
                  </el-date-picker>
                </el-col>
                <el-col class="line"
                        :span="0.5">
                  <div style="margin: 10px; text-align: center;">-</div>
                </el-col>
                <el-col :span="2">
                  <el-date-picker type="date"
                                  placeholder="结束日期"
                                  v-model="pageInfo.end"
                                  style="width: 140px;">
                  </el-date-picker>
                </el-col>
                <el-col class="line"
                        :span="0.5">
                  <div style="margin: 10px;"></div>
                </el-col>
                <el-col class="line"
                        :span="0.5">
                  <div style="margin-right: 16px;">
                    <el-input v-model="pageInfo.query"
                              placeholder="输入订单号"
                              @keyup.enter.native="getList()">
                    </el-input>
                  </div>
                </el-col>
                <el-button icon="el-icon-search"
                           type="success"
                           @click="getList()">查询</el-button>
              </div>
            </div>
          </div>

          <!-- 订单信息 -->
          <div>
            <el-tabs v-model="activeName"
                     @tab-click="handleClick">
              <el-tab-pane label="订单信息"
                           name="1">
                <div class="block">
                  <el-table :data="Orders"
                            style="width: 100% "
                            :row-class-name="tableRowClassName">
                    <el-table-column prop="id"
                                     width="100"
                                     label="订单号"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="created"
                                     label="订单日期"
                                     :formatter="dateFormat"
                                     align="center"
                                     width="180%">
                    </el-table-column>
                    <el-table-column prop="traderId"
                                     :label="type == '出库' ? '客户名':'供应商'"
                                     :formatter="setTraderName"
                                     align="center"
                                     width="300%">
                    </el-table-column>
                    <el-table-column prop="productId"
                                     label="产品名称"
                                     :formatter="setProductName"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="number"
                                     label="数量"
                                     align="center"
                                     :formatter="setProductUnit"
                                     width="120%">
                    </el-table-column>
                    <el-table-column prop="deleteFlag"
                                     label="总价"
                                     align="center"
                                     :formatter="setProductTotal"
                                     width="120%">
                    </el-table-column>
                    <el-table-column prop="status"
                                     label="状态"
                                     align="center"
                                     width="120%">
                      <template slot-scope="scope">
                        <div id="">
                          <el-button size="small"
                                     plain
                                     type="warning"
                                     v-if="scope.row.status"><i class="el-icon-loading"></i> 待审批</el-button>
                          <el-button size="small"
                                     plain
                                     type="success"
                                     v-if="!scope.row.status"><i class="el-icon-finished"></i> 已完成</el-button>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="address"
                                     label="操作"
                                     align="center"
                                     width="200%">
                      <template slot-scope="scope">
                        <el-row style="font-size: 18px;">
                          <el-button type="primary"
                                     plain
                                     size="small"
                                     @click="showOrder(scope.row)"><i class="el-icon-tickets"> </i> 查看</el-button>
                          <el-button type="success"
                                     plain
                                     size="small"
                                     @click="checkOrder(scope.row);"
                                     :disabled='status ? true : false'><i class="el-icon-edit-outline"> </i> 批准</el-button>
                        </el-row>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination @size-change="handleSizeChange"
                                 @current-change="handleCurrentChange"
                                 :current-page="pageInfo.pageNum"
                                 :page-sizes="[4, 8, 16]"
                                 :page-size="pageInfo.pageSize"
                                 layout="total, sizes, prev, pager, next, jumper"
                                 :total="totalPage">
                  </el-pagination>
                </div>
              </el-tab-pane>
              <!-- 发票信息 -->
              <el-tab-pane label="订单发票"
                           name="2">
                <div class="block">
                  <el-table :data="billList"
                            style="width: 100% "
                            :row-class-name="tableRowClassName">
                    <el-table-column type="index"
                                     width="100"
                                     label="序号"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="orderId"
                                     label="订单号"
                                     align="center"
                                     width="100%">
                    </el-table-column>
                    <el-table-column prop="created"
                                     label="发票日期"
                                     :formatter="dateFormat"
                                     align="center"
                                     width="200%">
                    </el-table-column>
                    <el-table-column prop="billNum"
                                     label="发票代码"
                                     align="center"
                                     width="220%">
                    </el-table-column>
                    <el-table-column prop="invoicNum"
                                     label="发票号码"
                                     align="center"
                                     width="220%">
                    </el-table-column>
                    <el-table-column prop="totalPrice"
                                     label="合计金额"
                                     :formatter="moneyFormat"
                                     align="center"
                                     width="200%">
                    </el-table-column>
                    <el-table-column prop="verifyNum"
                                     label="发票效验码"
                                     align="center"
                                     width="300%">
                    </el-table-column>
                    <el-table-column prop="address"
                                     label="操作"
                                     align="center"
                                     width="260%">
                      <template slot-scope="scope">
                        <el-row style="font-size: 18px;">
                          <el-button type="success"
                                     plain
                                     size="small"
                                     @click="downLoadBill(scope.row)"><i class="el-icon-download"> </i> 下载</el-button>
                          <el-button type="danger"
                                     plain
                                     size="small"
                                     @click="showDeleteBill(scope.row)">
                            <i class="el-icon-delete"> </i> 删除
                          </el-button>
                        </el-row>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination @size-change="handleSizeChange"
                                 @current-change="handleCurrentChange"
                                 :current-page="pageInfo.pageNum"
                                 :page-sizes="[4, 8, 16]"
                                 :page-size="pageInfo.pageSize"
                                 layout="total, sizes, prev, pager, next, jumper"
                                 :total="totalPage">
                  </el-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>

        </div>
      </div>
    </transition>
    <el-dialog title="查看订单"
               :visible.sync="orderShowVisible">
      <div style="text-align: center;width: 95%; margin-top: 20px;">
        <el-form :model="dialogOrder"
                 label-width="180px">
          <el-form-item label="产品名称">
            <div v-text="setProductName(dialogOrder,'')"></div>
          </el-form-item>
          <el-form-item label="数量">
            <div v-text="setProductUnit(dialogOrder,'')"></div>
          </el-form-item>
          <el-form-item label="总价">
            <div v-text="setProductTotal(dialogOrder,'')"></div>
          </el-form-item>
          <el-form-item label="交易日期">
            <div v-text="dateFormat(dialogOrder,'')"></div>
          </el-form-item>
          <el-form-item :label="type == '出库' ? '客户名':'供应商'">
            <div v-text="setTraderName(dialogOrder,'')"></div>
          </el-form-item>
          <el-form-item label=""
                        label-width="120px"
                        style="text-align: center;width: 96%;">
            <el-button @click="orderShowVisible = false"
                       type="danger"
                       plain><i class="el-icon-close"></i> 取 消
            </el-button>
            <el-button plain
                       type="primary"
                       :disabled="type == '入库' || status == false ? true : false"
                       @click="getBill()">
              <i class="el-icon-s-claim"></i> 生成发票
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog title="订单批准"
               :visible.sync="orderConfirmVisible">
      <el-form :model="confirmOrder"
               label-width="180px"
               style="text-align: center;width: 95%; margin-top: 20px;">
        <el-form-item label="产品名称">
          <div v-text="setProductName(confirmOrder,'')"></div>
        </el-form-item>
        <el-form-item label="数量">
          <div v-text="setProductUnit(confirmOrder,'')"></div>
        </el-form-item>
        <el-form-item label="总价">
          <div v-text="setProductTotal(confirmOrder,'')"></div>
        </el-form-item>
        <el-form-item label="交易日期">
          <div v-text="dateFormat(confirmOrder,'')"></div>
        </el-form-item>
        <el-form-item :label="type == '出库' ? '客户名':'供应商'">
          <div v-text="setTraderName(confirmOrder,'')"></div>
        </el-form-item>
        <el-form-item>
          <div style="text-align: center;width: 85%;">
            <el-button @click="orderConfirmVisible = false"
                       type="infor"><i class="el-icon-close"></i> 取 消
            </el-button>
            <el-button @click="doApplyRequst"
                       type="success"
                       plain><i class="el-icon-delete-solid"></i> 同 意
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="确定要删除？"
               :visible.sync="deleteBillVisible">
      <div style="color: #0077AA;font-size: 20px;text-align: center;font-weight: 700;"><i class="el-icon-info"></i>此操作无法复原，是否继续删除？</div>
      <div style="text-align: center;width: 100%; margin-top: 20px;">
        <el-button @click="deleteBillVisible = false"
                   type="infor"><i class="el-icon-close"></i> 取 消
        </el-button>
        <el-button @click="deleteBill()"
                   type="danger"
                   plain><i class="el-icon-delete-solid"></i> 删 除
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  created () {
    this.getUserInfo()
    this.getProducts()
    this.getTraders()
  },
  mounted () {
    this.getOrderList()
  },
  watch: {},
  methods: {
    async doApplyRequst () {
      this.orderConfirmVisible = false
      var id = this.confirmOrder.id
      const {
        data: result
      } = await this.$http.get('/order/updateStatus/' + id)
      if (result.status !== 200) return this.$message.error('请求失败')
      this.$message.success('请求成功')
      this.getOrderList()
    },
    getList () {
      if (this.activeName === 1) {
        this.getOrderList()
      } else {
        this.getBillList()
      }
    },
    showDeleteBill (val) {
      this.orderId = val.orderId
      this.deleteBillVisible = true
    },
    async deleteBill () {
      this.deleteBillVisible = false
      const {
        data: result
      } = await this.$http.delete('/bill/deleteById/' + this.orderId)
      if (result.status !== 200) return this.$message.error('发票删除失败')
      this.getBillList()
    },
    async getBill () {
      this.orderShowVisible = false
      var userId = window.sessionStorage.getItem('userId')
      window.location.href = `/download/tip/${this.orderId}/${userId}`
    },
    downLoadBill (val) {
      var userId = window.sessionStorage.getItem('userId')
      var orderId = val.orderId
      window.location.href = `${this.$http.defaults.baseURL}/download/tip/${orderId}/${userId}`
    },
    checkOrder (val) {
      this.orderConfirmVisible = true
      // this.orderId = val.id
      this.confirmOrder = val
    },
    setProductTotal (row) {
      var num = row.number
      var id = row.productId
      for (var p of this.Products) {
        if (p.id === id) return num * p.unitPrice + '.00元'
      }
    },
    setProductUnit (row) {
      var num = row.number
      var id = row.productId
      for (var p of this.Products) {
        if (p.id === id) return num + p.unit
      }
    },
    setTraderName (row) {
      var id = row.traderId
      for (var t of this.Traders) {
        if (t.id === id) return t.name
      }
    },
    setProductName (row) {
      var id = row.productId
      for (var p of this.Products) {
        if (p.id === id) return p.name
      }
    },
    // setNames() {
    //   if (this.Products == '' || this.Traders == '') {
    //     this.getProducts()
    //     this.getTraders()
    //   } else {
    //     for (var order of this.Orders) {
    //       var productId = order.productId
    //       var traderId = order.traderId
    //       var productName = this.Products. [productId].name
    //       var price = this.Products. [productId].unitPrice * order.number + '.00元'
    //       var traderName = this.Traders. [traderId].name
    //       order.productId = productName
    //       order.traderId = traderName
    //       order.deleteFlag = price
    //       order.number = order.number + this.Products. [productId].unit
    //       console.log(order)
    //       console.log(this.Products)
    //     }
    //   }

    // },
    dateFormat: function (row, column) {
      var t = new Date(row.created) // row 表示一行数据, updateTime 表示要格式化的字段名称
      return t.getFullYear() + '年' + (t.getMonth() + 1) + '月' + t.getDate() + '日'
    },
    moneyFormat: function (row, column) {
      var t = row.totalPrice // row 表示一行数据, updateTime 表示要格式化的字段名称
      return t + '.00元'
    },
    async getUserInfo () {
      const userId = window.sessionStorage.getItem('userId')
      const {
        data: result
      } = await this.$http.get('/user/getById/' + userId)
      this.UserInfo = result.data
      // console.log(this.UserInfo)
    },
    async getTraders () {
      const {
        data: result
      } = await this.$http.get('/trader/allList')
      if (result.status !== 200) return this.$message.error('供应商列表查询失败')
      this.Traders = result.data
      console.log(this.Traders)
    },
    async getProducts () {
      const {
        data: result
      } = await this.$http.get('/products/allList')
      if (result.status !== 200) return this.$message.error('产品列表查询失败')
      this.Products = result.data
    },
    // 获取发票列表
    async getBillList () {
      const {
        data: result
      } = await this.$http.get('/bill/list', { params: this.pageInfo })
      if (result.status !== 200) return this.$message.error('发票列表查询失败')
      console.log(result.data)
      this.billList = result.data.rows
      this.totalPage = result.data.total
      this.pageInfo.query = ''
    },
    async getOrderList () {
      if (this.status === false) {
        this.pageInfo.status = 1
      } else if (this.status === true) {
        this.pageInfo.status = 0
      } else { }
      if (this.type === '出库') {
        this.pageInfo.type = 1
      } else if (this.type === '入库') {
        this.pageInfo.type = 0
      } else { }
      console.log(this.pageInfo)
      const {
        data: result
      } = await this.$http.get('/order/billList', {
        params: this.pageInfo
      })
      if (result.status !== 200) return this.$message.error('申请单列表查询失败')
      this.Orders = result.data.rows
      this.totalPage = result.data.total
      this.pageInfo.query = ''
      // setTimeout(() => {
      //   this.setNames()
      // }, 100)
    },
    showOrder (order) {
      this.orderShowVisible = true
      this.dialogOrder = order
      this.orderId = order.id
      console.log(order)
      // var userId = window.sessionStorage.getItem('userId')
      // this.downLoadHref = `http://localhost:6660/download/tip/${this.orderId}/${userId}`
    },
    handleClick (tab, event) {
      this.getList()
      this.pageInfo.start = ''
      this.pageInfo.end = ''
    },
    tableRowClassName ({
      row,
      rowIndex
    }) {
      if (rowIndex === 1) {
        return 'warning-row'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    },

    handleSizeChange (val) {
      this.pageInfo.pageSize = val
      this.getList()
      // console.log(this.pageInfo);
    },
    handleCurrentChange (val) {
      this.pageInfo.pageNum = val
      this.getList()
      // console.log(`当前页: ${val}`);
    },
    open1 () {
      this.$notify({
        title: '成功',
        message: '操作成功！',
        type: 'success'
      })
    },

    open2 () {
      this.$notify({
        title: '警告',
        message: '请考虑后在操作！',
        type: 'warning'
      })
    },

    open3 () {
      this.$notify.info({
        title: '消息',
        message: '这是一条消息的提示消息'
      })
    },

    open4 () {
      this.$notify.error({
        title: '错误',
        message: '操作失败！'
      })
    }
  },
  data () {
    return {
      deleteBillVisible: false,
      downLoadHref: '',
      orderId: '',
      billList: '',
      confirmOrder: '',
      updateMsg: '',
      UserInfo: '',
      Traders: '',
      Products: '',
      orderConfirmVisible: false,
      orderShowVisible: false,
      leaveVisible: false,
      dialogOrder: '',
      Orders: '',
      request: '',
      type: '出库',
      status: true,
      pageInfo: {
        userId: '',
        type: '出库',
        start: '',
        end: '',
        query: '',
        status: 0,
        pageNum: 1,
        pageSize: 8
      },
      vacationObj: {
        processors: [2, 3]
      },
      vacationVisible: false,
      aplayActiveName: '1',
      activeName: '1',
      formLabelWidth: '90px',
      dialogFormVisible: false,
      show: true,
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      totalPage: 4
    }
  }
}
</script>

<style scoped lang="less">
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
