import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Login from '../views/Login.vue'
import Welcome from '../components/main/Welcome.vue'
import User from '../components/main/User.vue'
import Order from '../components/main/Order.vue'
import Salary from '../components/main/Salary.vue'
import Stock from '../components/main/Stock.vue'
import Staff from '../components/main/Staff.vue'
import Salarymg from '../components/main/Salarymg.vue'
import Boss from '../components/main/Boss.vue'
import Myorder from '../components/main/Myorder.vue'
import Apply from '../components/main/Apply.vue'
import Applycheck from '../components/main/Applycheck.vue'
import Checktips from '../components/main/Checktips.vue'
import Checkorder from '../components/main/Checkorder.vue'
import Trader from '../components/main/Trader.vue'
import Clientele from '../components/main/Clientele.vue'
import Products from '../components/main/Products.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', redirect: '/login'},
  {path: '/login', component: Login},
  {path: '/home', component: Home,redirect:"/welcome",
  children:[
    {path: '/welcome', component: Welcome},
    {path: '/myorder', component: Myorder},
    {path: '/apply', component: Apply},
    {path: '/user', component: User},
    {path: '/salary', component: Salary},
    //仓库权限
    {path: '/trader', component: Trader,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0 && ismanager != 3) return next("/login")
        next()
      }},
    {path: '/products', component: Products,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0 && ismanager != 3) return next("/login")
        next()
      }},
    {path: '/orders', component: Order,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0 && ismanager != 3) return next("/login")
        next()
      }},
    {path: '/stock', component: Stock,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0 && ismanager != 3){
          alert("无权限访问！将强制")
          return next("/login")}
          next()
      }},
    //人事权限
    {path: '/applycheck', component: Applycheck,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0 && ismanager != 2) return next("/login")
        next()
      }},
    {path: '/staff', component: Staff,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0 && ismanager != 2) return next("/login")
        next()
      }},
    //财务权限
    {path: '/checkorder', component: Checkorder,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0 && ismanager != 1) return next("/login")
        next()
      }},
    {path: '/checktips', component: Checktips,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0 && ismanager != 1) return next("/login")
        next()
      }},
    {path: '/clientele', component: Clientele,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0 && ismanager != 1) return next("/login")
        next()
      }},
    {path: '/salarymg', component: Salarymg,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0 && ismanager != 1) return next("/login")
        next()
      }},
     //boss权限
    {path: '/boss', component: Boss,
    beforeEnter: (to, from, next) => {
        let ismanager = window.sessionStorage.getItem('ismanager')
        if(ismanager != 0) return next("/login")
        next()
      }},
  ]},
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next) => {

  if(to.path === '/login')  return next()

  let token = window.sessionStorage.getItem('token')

  if(token) return next()

  next("/login")

})

export default router
