<template>
  <div>
    <transition name="fade">
      <div v-if="show" class="wrapper wrapper-content animated fadeInRight"
        style="background-color: white; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
        <!-- 3.定义栅格的一行 :gutter="20"定义行间距   :span="9" 占位符  -->
        <el-row :gutter="20">
          <el-col :span="9">
            <!-- 3.定义搜索框 -->
            <el-input placeholder="请输入内容" v-model="queryInfo.query" clearable @keyup.enter.native="getProductList">
              <el-button slot="append" icon="el-icon-search" @click="getProductList"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4">
            <!-- 定义添加按钮-->
            <el-button type="primary" @click="dialogVisible = true">添加货品</el-button>
          </el-col>
        </el-row>
        <!-- 用户数据表格展现  border 边框线属性  -->
        <el-table :data="userList" border stripe style="text-align: center;">
          <el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
          <el-table-column prop="mfrs" label="制造商" align="center" width="200" :formatter="formatName"></el-table-column>
          <el-table-column prop="type" label="货品分类" align="center" width="120"></el-table-column>
          <el-table-column prop="name" label="货品名称" align="center"></el-table-column>
          <el-table-column prop="model" label="货品编号" align="center" width="220"></el-table-column>
          <el-table-column prop="color" label="货品颜色" align="center" width="120"></el-table-column>
          <el-table-column prop="unitPrice" label="货品单价" :formatter="moneyType" align="center" width="160">
          </el-table-column>
          <el-table-column prop="status" label="状态" align="center" width="120">
            <template slot-scope="scope">
              <el-switch v-model="!scope.row.status" @change="updateStatus(scope.row)" active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="180">
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-edit" size="small" @click="updateUserBtn(scope.row)"></el-button>
              <el-button type="danger" icon="el-icon-delete" size="small" @click="deleteProduct(scope.row)"></el-button>
            </template>

          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum" :page-sizes="[5, 10, 20]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>

        <!-- 编辑用户新增对话框 visible.sync 控制对话框的显示-->
      </div>
    </transition>

    <el-dialog title="修改商品信息" :visible.sync="dialogVisible2" width="50%" @close="closeDialog">
      <el-form :model="editProduct" label-width="180px" class="demo-ruleForm">
        <el-form-item label="货品分类" style="width: 80%;">
          <el-select v-model="editProduct.type" placeholder="请选择分类">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="货品名称" style="width: 80%;">
          <el-input v-model="editProduct.name" ></el-input>
        </el-form-item>
        <el-form-item label="制造商" style="width: 80%;" :formatter="formatName">
          <template>
            <el-select v-model="editProduct.mfrs" placeholder="请选择">
              <el-option v-for="item in traderList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="型号" style="width: 80%;">
          <el-input v-model="editProduct.model" ></el-input>
        </el-form-item>
        <el-form-item label="货品颜色" style="width: 80%;">
          <el-input v-model="editProduct.color"></el-input>
        </el-form-item>
        <el-form-item label="货品单价" style="width: 80%;">
          <el-input placeholder="请输入单价" v-model="editProduct.unitPrice" type="number" class="input-with-select" style="width: 360px;" >
            <template slot="append">元</template>
          </el-input>
          <el-input placeholder="选择单位" :disabled="true" v-model="editProduct.unit" class="input-with-select" style="width: 140px; margin-left: 40px;">
            <el-select v-model="editProduct.unit"  slot="append" placeholder="请选择">
              <el-option label="件" value="件"></el-option>
              <el-option label="台" value="台"></el-option>
              <el-option label="部" value="部"></el-option>
              <el-option label="个" value="个"></el-option>
            </el-select>
          </el-input>

        </el-form-item>
        <el-form-item label="描述" style="width: 80%; text-align: center;">
          <el-input v-model="editProduct.remark"></el-input>
        </el-form-item>
        <el-form-item  style="width: 80%; text-align: center;">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="updateProduct">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="添加货品" :visible.sync="dialogVisible" width="50%" @close="closeDialog">
      <el-form :model="addProductModel" label-width="180px" class="demo-ruleForm">
        <el-form-item label="货品分类" style="width: 80%;">
          <el-select v-model="addProductModel.type" placeholder="请选择分类">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="货品名称" style="width: 80%;">
          <el-input v-model="addProductModel.name" ></el-input>
        </el-form-item>
        <el-form-item label="制造商" style="width: 80%;">
          <template>
            <el-select v-model="addProductModel.mfrs" placeholder="请选择" :formatter="formatName">
              <el-option v-for="item in traderList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="型号" style="width: 80%;">
          <el-input v-model="addProductModel.model" ></el-input>
        </el-form-item>
        <el-form-item label="货品颜色" style="width: 80%;">
          <el-input v-model="addProductModel.color"></el-input>
        </el-form-item>
        <el-form-item label="货品单价" style="width: 80%;">
          <el-input placeholder="请输入单价" v-model="addProductModel.unitPrice" type="number" class="input-with-select" style="width: 360px;" >
            <template slot="append">元</template>
          </el-input>
          <el-input placeholder="选择单位" :disabled="true" v-model="addProductModel.unit" class="input-with-select" style="width: 140px; margin-left: 40px;">
            <el-select v-model="addProductModel.unit"  slot="append" placeholder="请选择">
              <el-option label="件" value="件"></el-option>
              <el-option label="台" value="台"></el-option>
              <el-option label="部" value="部"></el-option>
              <el-option label="个" value="个"></el-option>
            </el-select>
          </el-input>

        </el-form-item>
        <el-form-item label="描述" style="width: 80%; text-align: center;">
          <el-input v-model="addProductModel.remark"></el-input>
        </el-form-item>
        <el-form-item  style="width: 80%; text-align: center;">
          <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUserBtn">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="确定要删除？" :visible.sync="deleteProductVisible">
      <div style="color: #0077AA;font-size: 20px;text-align: center;font-weight: 700;"><i class="el-icon-info"></i>此操作无法复原，是否继续删除？</div>
      <div style="text-align: center;width: 100%; margin-top: 20px;">
        <el-button @click="deleteProductVisible = false" type="infor"><i class="el-icon-close"></i> 取 消
        </el-button>
        <el-button @click="doDeleteProduct" type="danger" plain><i class="el-icon-delete-solid"></i> 删 除
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.getProductList()
      this.gettraderList()
    },
    data() {
      //校验手机号的邮箱规则
      return {
        select: '',
        show: true,
        queryInfo: {
          query: '',
          pageNum: 1,
          pageSize: 5
        },
        userList: [],
        //记录总数是数值类型
        total: 0,
        deleteProductVisible: false,
        dialogVisible: false,
        dialogVisible2: false,
        editProduct: '',
        addProductModel: {
          "type": "",
          "name": "",
          "model": "",
          "mfrs": '',
          "color": "",
          "unitPrice": '',
          "unit": "",
          "remark": "",
        },
        //数据校验规则
        ReposList: '',
        traderList: '',
        pageInfo: {
          userId: '',
          type: 1,
          query: '',
          pageNum: 1,
          pageSize: 100000
        },
        options: [{
          value: '商用',
          label: '商用'
        }, {
          value: '家用',
          label: '家用'
        }],
        value: '',
        delProduct: '',
        updateDialogVisible: false,
        //定义修改用户数据封装
        updateUserModel: {}
      }

    },
    methods: {
      async doDeleteProduct(){
        this.deleteProductVisible = false
        const {
          data: result
        } = await this.$http.delete('/products/'+this.delProduct.id)
        if (result.status !== 200) return this.$message.error("商品删除失败")
        this.$message.success("商品删除成功")
        this.getProductList()
      },
      deleteProduct(val){
        this.deleteProductVisible = true
        this.delProduct = val
      },
      formatName(row) {
        var id = row.mfrs
        for (var item of this.traderList) {
          if (item.id == id) {
            return item.name
          }
        }
      },
      async gettraderList() {
        this.pageInfo.start = "供应"
        const {
          data: result
        } = await this.$http.get('/trader/list', {
          params: this.pageInfo
        })
        if (result.status !== 200) return this.$message.error("供应商列表查询失败")
        this.traderList = result.data.rows
        console.log(this.traderList)
      },
      moneyType(val) {
        console.log(val)
        return val.unitPrice + ".00元"
      },
      async getProductList() {
        const {
          data: result
        } = await this.$http.get('/products/list', {
          params: this.queryInfo
        })
        if (result.status !== 200) return this.$message.error("货品列表查询失败")
        this.userList = result.data.rows
        this.total = result.data.total
        console.log(result.data.rows)
        console.log("总记录数:" + this.total)
      },
      async updateStatus(product) {
        const {
          data: result
        } = await this.$http.put(`/products/updatestatus/${product.id}/${product.status}`)
        if (result.status !== 200) return this.$message.error("商品状态修改失败!")
        this.$message.success("商品状态修改成功!")
        this.getProductList()
      },

      handleSizeChange(pageSize) {
        //console.log("每页展现的条数"+pageSize)
        this.queryInfo.pageSize = pageSize
        this.getProductList()
      },
      handleCurrentChange(pageNum) {
        //console.log("页数:"+pageNum)
        this.queryInfo.pageNum = pageNum
        this.getProductList()
      },
      closeDialog() {
        //重置表格数据
        this.$refs.addUserRef.resetFields()
      },
      //校验用户数据
      async addUserBtn() {
          const {
            data: result
          } = await this.$http.post('/products/insertproducts', this.addProductModel)
          if (result.status !== 200) return this.$message.error("货品新增失败")
          this.$message.success("货品新增成功")
          //关闭对话框
          this.dialogVisible = false
          //重新获取用户列表
          this.getProductList()
      },
      async updateProduct() {
        this.dialogVisible2 = false
        const {
          data: result
        } = await this.$http.put("/products/updateproducts",this.editProduct)
        if (result.status !== 200) return this.$message.error("商品更新失败")
        this.$message.success("商品更新成功")
        this.getProductList()
      },
      updateUserBtn(product) {
        this.dialogVisible2 = true
        this.editProduct = product
      },
      closeUpdateDialog() {
        //重置表格数据
        this.$refs.updateUserRef.resetFields()
      },
    },
    //利用钩子函数实现数据查询
  }
</script>



<style lang="less" scoped>

</style>
