import { render, staticRenderFns } from "./Myorder.vue?vue&type=template&id=0a7eb07d&scoped=true&"
import script from "./Myorder.vue?vue&type=script&lang=js&"
export * from "./Myorder.vue?vue&type=script&lang=js&"
import style0 from "./Myorder.vue?vue&type=style&index=0&id=0a7eb07d&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a7eb07d",
  null
  
)

export default component.exports