<template>
  <div id="main">
    <el-container class="main" >
      <el-aside :width="rightButFlag ? '64px' : '200px' " >
        <div class="right-aside"  style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);" >
          <div class="right-title" v-show="!rightButFlag">
            <div id="outputsvg">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.2" baseProfile="tiny" id="图层_1" x="0px" y="0px" viewBox="0 0 214 218.9" overflow="visible" xml:space="preserve">
              <path d="M157.2,146.6c-0.4,2.9,1.1,5,2.6,7.3c1.3,2,2.9,4.1,3.2,6.3c1,6.3-2.5,11.2-9.4,10.2c-2.2-0.3-4.3-1.7-6.3-2.8  c-4.6-2.8-9-5.8-13.5-8.6c-2.9-1.8-4-4.6-4.7-7.7c-0.9-4-1.7-8.1-2.5-12.1c-0.2-1.1-0.2-2.3-0.4-3.5c-0.3-1.5-0.6-3-1.2-4.4  c-0.7-1.5-1.5-2.9-0.5-4.4c0.9-1.3,2.4-0.9,3.7-0.7c1.5,0.2,3.1,0.1,4.6,0.4c1.7,0.4,3.2,1.1,4.8,1.7c0.2,0.1,0.4,0.4,0.6,0.4  c6.2,1.2,9.5,6.4,14,10c1.1,0.9,2.1,1.9,3.2,2.8C157.3,142.9,157.3,144.7,157.2,146.6z"/>
              <path fill="#0D0000" d="M47.3,133.8c1.1-2.4,19.2-12.3,25.4-19.1c3.3-3.5,9.3-7.7,13.7-10.9c3.1-4.7,9.3-7,13.4-10.9  c3.9-1.6,3.4-33.9,2.4-39.1c1-8.7-1.1-17.5-1.4-26.1c0.3-4.3-3.8-7-5.4-10.7C93.6,12.6,79.8,9,88,4.2c4-0.6,7.9-0.1,11.1-3.3  c10.5-3.3,25.1,9.1,35.2,12.7c4.3,2.1,9.3,9.2,4.2,13.1c-1.8,1.3-2.2,3.1-2.2,5.1c1.5,7.2-1.3,14.2-4.9,20.3  c-2.7,3.2-4.8,7.4-4.8,11.5c0.8,5,2.1,9.6,0.3,14.7c26-10.2,59.4-0.9,79.5,17.7c9.1,6.5,5,19.4,5.3,29.1c-0.1,3.9-1.1,7.5-2.8,10.9  c-3.9,3.2-3,8-4.2,12.1c-1.9,7.8-6.6,14.3-11.2,20.7c-2.9,1-2.8,3.3-3.1,5.8c-0.4,4.8-4.6,7.7-7.4,11.2c-1.1,2.8-3.5,3.8-6.1,4.1  c-5.4,0.7-0.7,7.7-9.6,11.6c-6.2,3.7-9.9,12.4-18.3,10.4c-2.6-0.1-4.8,0.5-6.5,2.6c-2.4,1.6-5.6,2.4-8.1,3.9  c-2.6,1.4-4.8,0.3-7.1-0.7c-1.7-0.6-4-0.2-5.6-1.1c-2.1-3.1,3.2-3.1,4.5-5c4.1-5.5,10.2-10.5,17-11.8c6.4-4.1,10-11.1,15.9-15.5  c1.6-0.8,2.7-1.9,3.3-3.7c1.5-5.6,5.8-9.2,9.6-13.3c2.8-4.6,2.7-10.1,6-14.6c4.2-7.4,5.8-15.5,5.9-24c4.5-10.4,4.1-21.8-1.2-31.9  c-10.5-12.3-27-11.6-41.2-7.3c-13.3,3.7-24,14.2-31.7,25.2c-0.6,1.3-1.2,2.3-2.8,2.6c-5.8,2.2-4.5,10.6-9.4,13.8  c-3.6,1.7-2.7,5.9-3.9,9c-1,2.2-4.3,4.2-5.4,0.9c-0.1-0.2-0.4-0.3-1-0.9c-0.8,5.8-1.5,10.9-6,14.9c-2.3,1.8-1.3,6.4-4.8,6.7  c-1.7,0.2-2.2,1.4-2.1,3c0.8,4.3-4.8,5.1-5,9.1c-0.5,3.4-2.9,5.5-5.4,7.5c-5.2,2.6-8,8.4-13.3,10.7c-2.1,0.7-4.1,1.1-5.8,2.8  c-5.3,3.8-11.7-3.3-16.9-4.9c-1.3-0.7-3.4-1.1-3.8-2.2c-2.2-8.4-0.2-28.4,8.1-33c1.5-0.8,2.5-1.5,2.1-3.4c0.2-6.2,8.2-15.2,13.6-18"/>
              <path fill="#367fa9" d="M47.3,167.5c0.5,2.1,0.5,5,3.2,4.6c1.2-0.1,1.6-0.9,1.5-2.1c-0.1-1.9,1.1-2.8,2.8-3.2c2.1-0.6,4.7,0,5.1-2.7  c0.8-2.9,0.7-6.1,4.4-6.4c4-0.7-0.1-5.7,5.1-8.1c4-2.3,5.6-6.9,8.9-9.9c1.8-1.9,4.4-3.2,4.7-6.4c-0.1-4.7,5.4-0.5,5.4-4.2  c-2.7-8.7,4.3-11.1,5.4-19c-6-0.6-8.3,7.3-11.8,11c-3.6,2.2-6.1,5.7-8.8,8.9c-6.4,4.5-11.8,6.5-14.1,14.9  C56.6,153.5,49,159,47.3,167.5z"/>
              <g>
              	<path d="M72.4,41.9L50.4,95l22.9-19.6l3.1-15.5l9.1,31.6l-57.2,28.3l10.6-53.9L21.9,76.6l-1.6,14L6.9,62.2L72.4,41.9z"/>
              </g>
              <g>
              	<path fill="#ffffff" d="M66,39.5L44,92.7L66.9,73L70,57.5L79,89.1l-57.2,28.3l10.6-53.9L15.5,74.3l-1.6,14L0.5,59.8L66,39.5z"/>
              </g>
              </svg>
            </div>
            <div class="right-logo">
              ZhiWei
            </div>
          </div>
          <div class="right-menu">
            <el-menu background-color="#367fa9" active-text-color="#4094ff" unique-opened
        :collapse="rightButFlag" :collapse-transition="!rightButFlag" router  :default-active="defaultActive">
              <el-submenu :index="item.id+''" v-for="item in Rights" :key="item.id">
                <template slot="title" >
                  <i :class="menuIcon[item.id]" style="color: white;"></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style="font-size: 18px;font-weight: 700;color: white;">{{item.name}}</span>
                </template>
                <el-menu-item :index="child.path" v-for="child in item.children" :key="child.id" @click="defaultActiveMenu(child.path)">
                  <template slot="title">
                    <i :class="menuIcon[child.id]" style="color: white;"></i>
                    <span style="font-size: 16px;font-weight: 700;color: white;">{{child.name}}</span>
                  </template>
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </el-aside>
      <el-container>
        <el-header >
          <div ref="Header" @mouseenter="getMsgNum" class="header">
            <div class="rightBut" @mouseenter="colorChan5()" @mouseleave="colorChan6()">
              <div style="font-size: 18px; font-weight: 700; height: 30px; margin-top: 17px;" @click="rightBut()">
                <i class="el-icon-s-fold" v-show="!rightButFlag" /><i class="el-icon-s-unfold" v-show="rightButFlag" />
              </div>
            </div>
            <div class="header-title " @mouseenter="colorChan1()" @mouseleave="colorChan3()">
              <div style="font-size: 18px; font-weight: 700; height: 30px; margin-top: 17px;" @click="pushHome()">
                <i class="el-icon-s-data" />业绩信息
              </div>
            </div>

            <div ref="rightMsg" style="position: absolute;width: 600px;margin-right: 40px;right: 0px;">
              <div class="user-msg">
                <el-badge :value="MsgNum" class="item" :type="MsgNum>0?'':'primary'">
                  <el-button size="small" @click="toDo()"><i class="el-icon-message" />待办</el-button>
                </el-badge>
              </div>
              <div class="user-head" style="width: 80px; height: 60px;">
                <el-dropdown @command="handleCommand">
                  <span class="el-dropdown-link">
                    <div class="box-shadow head-img" style="width: 50px; height: 50px;overflow: hidden;">
                      <img :src="user.imgPath" style="width: 50px;" />
                    </div>
                    <div class="user-name" style="font-size: 16px;">
                      <span v-text="user.username"></span><i class="el-icon-arrow-down el-icon--right"></i>
                    </div>
                  </span>
                  <el-dropdown-menu slot="dropdown" >
                    <el-dropdown-item command="1"><i class="el-icon-user-solid" />个人中心&nbsp;&nbsp;</el-dropdown-item>
                    <el-dropdown-item command="2"><i class="el-icon-key" />更改密码&nbsp;&nbsp;</el-dropdown-item>
                    <el-dropdown-item command="3"><i class="el-icon-switch-button" />退出登录&nbsp;&nbsp;</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </el-header>
        <el-main >
          <router-view></router-view>
        </el-main>
        <el-footer class="footer" style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
          <div class="row" style="font-size: 14px;">
            <div class="col-sm-12" style="margin-top: 10px;">
              <el-link type="info" href="mailto:mail@baochaofei.cn" target='_blank' :underline="false" class="el-icon-chat-dot-square">意见箱</el-link>
              <i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
              <el-link type="info" class="el-icon-milk-tea" target='_blank' :underline="false" href="https://gitee.com/organizations/erp-zhiwei/members/list">开发团队</el-link>
            </div>
          </div>
          </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import $ from 'jquery'
  export default {
    mounted() {
      this.getUserInfo()
      this.getMenuList()
      this.getMsgNum()
    },
    data() {
      return {
        count: 0,
        MsgNum: 0,
        menuIcon: {
          '1': 'el-icon-user-solid',
          '2': 'el-icon-s-home',
          '3': 'el-icon-s-cooperation',
          '4': 'el-icon-s-marketing',
          '6': 'el-icon-s-finance',
          '7': 'el-icon-tickets',
          '9': 'el-icon-shopping-cart-full',
          '8': 'el-icon-document-checked',
          '10': 'el-icon-coin',
          '11': 'el-icon-thumb',
          '12': 'el-icon-house',
          '13': 'el-icon-user',
          '14': 'el-icon-fork-spoon',
          '15': 'el-icon-edit-outline',
          '16': 'el-icon-truck',
          '17': 'el-icon-shopping-cart-1',
          '18': 'el-icon-edit',
          '19': 'el-icon-shopping-bag-2',
          '21': 'el-icon-bank-card',
          '22': 'el-icon-position',
          '24': 'el-icon-collection-tag'
        },
        defaultActive: '',
        isCollapse: false,
        Rights: [],
        rightButFlag: false,
        userName: '测试用户',
        user: ''
      }
    },
    methods: {
      async getUserInfo(){
        let userId = window.sessionStorage.getItem('userId')
        const {data: result} = await this.$http.get("/user/getById/"+userId)
        this.user = result.data
      },
      async toDo(){
        var userId = window.sessionStorage.getItem('userId')
        var level = window.sessionStorage.getItem('ismanager')
        if(level == 1 && this.MsgNum>0){
          this.$router.push('/checktips')
        }else if(level == 2 && this.MsgNum>0){
          this.$router.push('/applycheck')
        }else if(level == 0 && this.MsgNum>0){
          this.$router.push('/boss')
        }else if(level == 3 && this.MsgNum>0){
          this.$router.push('/orders')
        }
        console.log(userId)
        const {data: result} =  await this.$http.put('/user/cleanMsgNum/'+ userId)
        if(result.status !== 200) return this.$message.error("待办事项查询失败")
        this.getMsgNum()
      },
      handleCommand(command) {
        console.log("点击")
              if(command == 1){
                this.$router.push('/user')
              }else if(command == 2){
                this.$router.push('/user')
              }else if(command == 3){
                this.logOut()
              }else{}
            },
      logOut(){
        console.log("点击")
        window.sessionStorage.removeItem("token")
        this.$router.push('/')
      },
      pushHome() {
        this.$router.push('/home')
      },
      defaultActiveMenu(activeMenu){
        //为了实现返回之后的选中效果,应该将数据保存到第三方中sessionStory
        window.sessionStorage.setItem("activeMenu",activeMenu)
        this.defaultActive = activeMenu
      },
 //延迟的方法
       sleep(numberMillis){
        var now = new Date();
        var exitTime = now.getTime() + numberMillis;
        while (true) {
          now = new Date();
          if (now.getTime() > exitTime) return;
        }
      },

      async getMsgNum() {
        var userId = window.sessionStorage.getItem('userId')
          const {data: result} =  await this.$http.get('/user/getMsgNum/'+userId)
          if(result.status !== 200) return this.$message.error("信息数查询失败")
          this.MsgNum = result.data
          console.log("更新信息条数")
       },

      async getMenuList() {
       const {data: result} =  await this.$http.get('/rights/getRightsList')
       if(result.status !== 200) return this.$message.error("左侧菜单查询失败")
       let ismanager = window.sessionStorage.getItem('ismanager')
       if(ismanager == 0){
       }else if(ismanager == 1){
         result.data.splice(1,2)
         result.data.splice(2,1)
       }else if(ismanager == 2){
         result.data.splice(1,1)
         result.data.splice(2,2)
       }else if(ismanager == 3){
         result.data.splice(2,3)
       }else{
         result.data.splice(1,4)
       }
       this.Rights = result.data
      },
      rightBut() {
        this.rightButFlag = !this.rightButFlag
        if (this.rightButFlag == true) {
          this.$refs.rightMsg.style.transform = `translateX(136px)`
        } else {
          this.$refs.rightMsg.style.transform = `translateX(0px)`
        }
      },
      colorChan1() {
        this.$refs.Header.children[1].style.backgroundColor = "#367fa9"
      },
      colorChan2() {
        this.$refs.Header.children[2].style.backgroundColor = "#367fa9"
      },
      colorChan3() {
        this.$refs.Header.children[1].style.backgroundColor = ""
      },
      colorChan4() {
        this.$refs.Header.children[2].style.backgroundColor = ""
      },
      colorChan5() {
        this.$refs.Header.children[0].style.backgroundColor = "#367fa9"
      },
      colorChan6() {
        this.$refs.Header.children[0].style.backgroundColor = ""
      },
    }
  }
</script>

<style scoped lang="less">
  .div-inline{ display:inline}
  .header{
    width: 100%;
    height: 6%;
    position: absolute;
    // float:right;
    // transform: translate(100%,0%);
  }
  #outputsvg{
    position: absolute;
    transform: translate(20px,-0px);
  }
  .el-button {
    font-size: 14px;
    font-weight: 700;
    margin-left: 15px;
    background-color: white;
  }
  .right-logo{
    font-size: 26px;
    font-weight: 800;
    color: white;
    height: 30px;
    width: 70px;
    position: absolute;
    transform: translate(86px,10px);
    // margin-top: 0px;
  }
  .box-shadow {
    box-shadow: 0px 0px 7px #888888;
  }

  .rightBut {
    position: absolute;
    height: 60px;
    width: 60px;
    transform: translate(-20px, 0px);
    // background-color: #5b9bd5;
    text-align: center;
    color: white;
  }
.user-msg {
    // background-color: black;
    position: absolute;
    height: 40px;
    width: 300px;
    transform: translate(40px, 14px);
  }
  .header-title {
    position: absolute;
    height: 60px;
    width: 100px;
    transform: translate(40px, 0px);
    // background-color: #5b9bd5;
    text-align: center;
    color: white;
  }

  .user-head {
    position: absolute;
    transform: translate(240px, 4px);
  }

  .user-name {
    width: 90px;
    height: 60px;
    position: absolute;
    transform: translate(50px, -35px);
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #000000;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .head-img {
    background-color: white;
    border-radius: 25px;
  }

  #main {
    height: 100%;
  }

  .main {
    height: 100%;
    width: 100%;
  }

  .home-container {}

  .el-header {
    background-color: #3c8dbc;
    color: #333;
    text-align: center;
  }

  .el-footer {
    background-color: #ffffff;
    color: #333;
    text-align: center;
    transform: translate(15px);
  }

  .el-aside {
    background-color: #d9dee2;
    color: #333;
    // text-align: center;
    // line-height: 100vh;
    height: 100%;
  }

  .right-aside {}

  .right-menu{
  }

  .right-title {
    background-color: #367fa9;
    height: 60px;
  }

  .el-main {
    background-color: #E9EEF3;
    color: #333;
  }

  body>.el-container {
    height: 100%;
  }

  .el-container {}

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 100%;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 100%;
  }
</style>
