<template>
  <transition name="fade">
    <div class="wrapper wrapper-content animated fadeInRight">
      <!-- <h5 v-if="flag"> -->
      <div class="row">
        <div class="col-sm-13">
          <div class="block">
            <el-carousel indicator-position="outside"
                         :height="banH +'px'"
                         interval="5000">
              <el-carousel-item v-for="item in images"
                                :key="item.id">
                <img :src="item.idView"></img>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-13">
          <div class="ibox float-e-margins"
               style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
            <div class="ibox-title">
              <h5>订单</h5>
            </div>
            <div class="ibox-content">
              <div class="row">
                <div class="">
                  <div class="flot-chart col-sm-9">
                    <div style="height: 220px;"
                         ref="flotChart"></div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <ul class="stat-list">
                    <li v-for="item in orderTab">
                      <h2 class="no-margins">{{item.num}}</h2>
                      <small>{{item.title}}</small>
                      <div class="stat-percent">{{item.persent}} <i class="text-navy"
                           :class="titleMsgIcon[item.state]"></i>
                      </div>
                      <div class="progress progress-mini">
                        <div :style="`width: ${item.persent};`"
                             class="progress-bar"></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- </h5> -->
  </transition>
</template>

<script>
export default {
  mounted () {
    this.getMonthOrder()
    this.getOrderInfo()
    setTimeout(() => {
      this.painChart()
      this.flag = true
    }, 500)
    const _this = this
    window.onresize = function () {
      _this.chart.resize()
    }
  },
  data () {
    return {
      countNet: 0,
      images: [
        { id: 0, idView: 'zhiwei01.jpg' },
        { id: 1, idView: 'zhiwei02.jpg' },
        { id: 2, idView: 'zhiwei03.jpg' },
        { id: 3, idView: 'zhiwei04.jpg' }
      ],
      banH: 500,
      msgFlag: true,
      msgArray: 'el-icon-arrow-up',
      msgBoxFlag: true,
      chart2: '',
      titleMsg: [
        {
          title: '收入',
          date: '月',
          desc: '总收入',
          num: '4,886,200',
          persent: '85%',
          state: 2
        },
        {
          title: '订单',
          date: '全年',
          desc: '新订单',
          num: '26,200',
          persent: '20%',
          state: 1
        },
        {
          title: '访客',
          date: '今天',
          desc: '新访客',
          num: '3,280',
          persent: '24%',
          state: 1
        },
        {
          title: '活跃用户',
          date: '最近一月',
          desc: '12月',
          num: '6,200',
          persent: '23%',
          state: 0
        }
      ],
      orderTab: [
        {
          num: '2,346',
          title: '本月入库',
          persent: '48%',
          state: 1
        },
        {
          num: '4,836',
          title: '本月订单',
          persent: '60%',
          state: 0
        },
        {
          num: '9,366',
          title: '本月销售额',
          persent: '22%',
          state: 2
        }
      ],
      titleMsgIcon: {
        2: 'fa fa-bolt',
        1: 'fa fa-level-up',
        0: 'fa fa-level-down',
        7: 'fa fa-check-square', // 勾选
        8: 'fa fa-square-o', // 未勾选
        10: 'todo-completed' // 完成
      },
      things: [
        {
          title: '开会',
          done: true,
          leftTime: '1小时'
        },
        {
          title: '项目发布',
          done: true,
          leftTime: '1小时'
        },
        {
          title: '修改bug',
          done: false,
          leftTime: '1小时'
        }
      ],
      titleMsgColor: {
        txt: ['label-success', 'label-info', 'label-primary', 'label-danger'],
        ico: ['text-success', 'text-info', 'text-navy', 'text-danger']
      },
      flag: false,
      chart: null,
      msgsTotol: 20,
      msgs: [
        {
          name: '井幽幽',
          msg: '有人说：“一辈子很长，要跟一个有趣的人在一起”。我想关注我的人，应该是那种喜欢找乐子也乐意分享乐趣的人，你们一定挺优秀的。所以单身的应该在这条留言，互相勾搭一下。特别有钱人又帅可以直接私信我！',
          timesAgo: '1月前',
          date: '4月11日 00:00'
        }
      ],

      option: {
        grid: {
          left: 60,
          right: '6%',
          height: '65%',
          bottom: 24
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: {
              show: true,
              readOnly: false
            },
            magicType: {
              show: true,
              type: ['line', 'bar']
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        legend: {
          data: ['订单量', '入库量', '月收益']
        },
        xAxis: [
          {
            type: 'category',
            data: [
              '1月',
              '2月',
              '3月',
              '4月',
              '5月',
              '6月',
              '7月',
              '8月',
              '9月',
              '10月',
              '11月',
              '12月'
            ],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '订单数',
            min: 0,
            max: 1500,
            interval: 300,
            axisLabel: {
              formatter: '{value} 单'
            }
          },
          {
            type: 'value',
            name: '收益',
            min: 0,
            max: 50,
            interval: 10,
            axisLabel: {
              formatter: '{value} 元'
            }
          }
        ],
        series: [
          {
            name: '订单量',
            type: 'bar',
            data: [],
            color: 'rgb(54, 127, 169)'
          },
          {
            name: '入库量',
            type: 'bar',
            data: [],
            color: 'rgb(0, 0, 0)'
          },
          {
            name: '月收益',
            type: 'line',
            yAxisIndex: 1,
            data: [],
            color: 'rgb(26, 179, 148)'
          }
        ]
      }
    }
  },
  methods: {
    async getMonthOrder () {
      const { data: result } = await this.$http.get('/order/thisMonth')
      if (result.status !== 200) { return this.$message.error('本月订单信息查询失败') }
      this.orderTab = result.data
    },
    async getOrderInfo () {
      const { data: result } = await this.$http.get('/order/info')
      if (result.status !== 200) return this.$message.error('订单信息查询失败')
      this.option.series[0].data = result.data.outNum
      this.option.series[1].data = result.data.inNum
      this.option.series[2].data = result.data.incomeNum
      var max = 0
      for (var n of result.data.outNum) {
        max = n > max ? n : max
      }
      for (var m of result.data.inNum) {
        max = m > max ? m : max
      }
      var interval = Math.ceil(max / 5)
      this.option.yAxis[0].max = 5 * interval
      this.option.yAxis[0].interval = interval
      var maxin = 0
      for (var p of result.data.incomeNum) {
        maxin = p > maxin ? p : maxin
      }
      var interval2 = Math.ceil(maxin / 5) * 1.8
      this.option.yAxis[1].max = 5 * interval2
      this.option.yAxis[1].interval = interval2
      this.countNet++
    },
    painChart () {
      this.chart = this.$echarts.init(this.$refs.flotChart)
      this.chart.setOption(this.option)
    },

    closeMsg () {
      this.msgBoxFlag = false
    }
  },
  directives: {
    drag: {}
  },
  watch: {
    countNet (val, oldVal) { // 普通的watch监听
      setTimeout(() => {
        this.painChart()
      }, 1000)
      //
    },
    msgFlag: function (flag) {
      if (flag == true) {
        this.msgArray = 'el-icon-arrow-up'
      } else {
        this.msgArray = 'el-icon-arrow-down'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

/* v-enter-active 【入场动画的时间段】 */
/* v-leave-active 【离场动画的时间段】 */
.v-enter-active,
.v-leave-active {
  transition: all 0.8s ease;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 2.75;
  line-height: 270px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
img {
  width: 100%;
  height: 100%;
}

.welcome {
  height: 100%;
}
</style>
