<template>
  <div id="">

    <transition name="fade">
      <div v-if="show"
           class="wrapper wrapper-content animated fadeInRight"
           style="background-color: white; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
        <div style="background-color: white;">
          <div style="margin-bottom: 10px;font-size: 18px;color: #3c8dbc; font-weight: 700;">
            <span>订单信息</span>
            <div style="padding-top: 10px;">
              <el-col :span="3">
                <el-radio-group v-model="type"
                                v-if="activeName == 'first3' ? true : false"
                                fill="#67c03a"
                                text-color="#ffffff"
                                @change="getOrderOutList()">
                  <el-radio-button label="出库"></el-radio-button>
                  <el-radio-button label="入库"></el-radio-button>
                </el-radio-group>
              </el-col>
            </div>
            <div id="">
              <el-input placeholder="请输入内容"
                        class="input-with-select"
                        style="width: 400px;margin-left: 10px; margin-right: 10px;"
                        slot="append"
                        v-model="pageInfo.query"
                        @keyup.enter.native="getList()">
                <el-button slot="append"
                           icon="el-icon-search"
                           type="success"
                           @click="getList()">搜索</el-button>
              </el-input>
            </div>
          </div>
          <div>
            <el-tabs v-model="activeName"
                     @tab-click="handleClick">
              <!-- 第一张表 入库订单 -->
              <el-tab-pane label="入库订单"
                           name="first">
                <div class="block">
                  <el-table :data="OrderList"
                            style="width: 100% "
                            :row-class-name="tableRowClassName">
                    <el-table-column type="index"
                                     width="100"
                                     label="序号"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="name"
                                     label="产品名称"
                                     align="center"
                                     width="300">
                    </el-table-column>
                    <el-table-column prop="model"
                                     label="产品型号"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="number"
                                     label="数量"
                                     align="center"
                                     width="100">
                    </el-table-column>
                    <el-table-column prop='unitPrice'
                                     label="单价"
                                     :formatter="formatPrice"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="created"
                                     label="下单时间"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="username"
                                     label="下单人"
                                     align="center">
                    </el-table-column>
                    <el-table-column label="状态"
                                     align="center">
                      <template slot-scope="scope">
                        <div id="">
                          <el-button size="small"
                                     plain
                                     type="warning"
                                     v-if="!scope.row.status"><i class="el-icon-loading"></i> 进行中</el-button>
                          <el-button size="small"
                                     plain
                                     type="success"
                                     v-if="scope.row.status"><i class="el-icon-finished"></i> 已完成</el-button>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作"
                                     align="center">
                      <template slot-scope="scope">
                        <el-row style="font-size: 18px;">
                          <el-button type="primary"
                                     plain
                                     size="small"
                                     @click="outbound(scope.row)"><i class="el-icon-tickets"> </i> 入库</el-button>
                          <el-button type="danger"
                                     plain
                                     size="small"
                                     @click="deleteRequst(scope.row.id);"><i class="el-icon-delete"> </i> 删除</el-button>
                        </el-row>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination @size-change="handleSizeChange"
                                 @current-change="handleCurrentChange"
                                 :current-page="pageInfo.pageNum"
                                 :page-sizes="[5, 10, 15]"
                                 :page-size="pageInfo.pageSize"
                                 layout="total, sizes, prev, pager, next, jumper"
                                 :total="totalPage">
                  </el-pagination>
                </div>
              </el-tab-pane>

              <!-- 第二张表 出库表格 -->
              <el-tab-pane label="出库订单"
                           name="first2">
                <div class="block">
                  <el-table :data="RemoveList"
                            style="width: 100% "
                            :row-class-name="tableRowClassName">
                    <el-table-column type="index"
                                     width="100"
                                     label="序号"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="name"
                                     label="产品名称"
                                     align="center"
                                     width="300">
                    </el-table-column>
                    <el-table-column prop="model"
                                     label="产品型号"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="number"
                                     label="数量"
                                     align="center"
                                     width="100">
                    </el-table-column>
                    <el-table-column prop='unitPrice'
                                     label="单价"
                                     :formatter="formatPrice"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="created"
                                     label="下单时间"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="username"
                                     label="下单人"
                                     align="center">
                    </el-table-column>
                    <el-table-column label="状态"
                                     align="center">
                      <template slot-scope="scope">
                        <div id="">
                          <el-button size="small"
                                     plain
                                     type="warning"
                                     v-if="!scope.row.status"><i class="el-icon-loading"></i> 进行中</el-button>
                          <el-button size="small"
                                     plain
                                     type="success"
                                     v-if="scope.row.status"><i class="el-icon-finished"></i> 已完成</el-button>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作"
                                     align="center">
                      <template slot-scope="scope">
                        <el-row style="font-size: 18px;">
                          <el-button type="primary"
                                     plain
                                     size="small"
                                     @click="outboundIn(scope.row)"><i class="el-icon-tickets"> </i>出库</el-button>
                          <el-button type="danger"
                                     plain
                                     size="small"
                                     @click="deletetrue(scope.row.id);"><i class="el-icon-delete"> </i>删除</el-button>
                        </el-row>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-pagination @size-change="handleSizeChange"
                                 @current-change="handleCurrentChange"
                                 :current-page="pageInfo.pageNum"
                                 :page-sizes="[5, 10, 15]"
                                 :page-size="pageInfo.pageSize"
                                 layout="total, sizes, prev, pager, next, jumper"
                                 :total="totalPage">
                  </el-pagination>
                </div>
              </el-tab-pane>
              <!-- 第三章表  已出库表格 -->
              <el-tab-pane label="成功订单"
                           name="first3">
                <div class="block">
                  <el-table :data="OrderOutList"
                            style="width: 100% "
                            :row-class-name="tableRowClassName">
                    <el-table-column type="index"
                                     width="100"
                                     label="序号"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop='type'
                                     label="订单类型"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="name"
                                     label="产品名称"
                                     align="center"
                                     width="300">
                    </el-table-column>
                    <el-table-column prop="model"
                                     label="产品型号"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="number"
                                     label="数量"
                                     align="center"
                                     width="100">
                    </el-table-column>
                    <el-table-column prop='unitPrice'
                                     label="单价"
                                     :formatter="formatPrice"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="username"
                                     label="总价"
                                     :formatter="formattotalPrice"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="created"
                                     label="下单时间"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="tradername"
                                     label="订货方"
                                     align="center">
                    </el-table-column>
                    <el-table-column prop="username"
                                     label="下单人"
                                     align="center">
                    </el-table-column>

                    <!-- <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                      <div id="">
                       <el-button size="small" plain type="warning" v-if="!scope.row.status"><i class="el-icon-loading"></i> 进行中</el-button>
                       <el-button size="small" plain type="success" v-if="scope.row.status"><i class="el-icon-finished"></i> 已完成</el-button>
                      </div>
                    </template>
                  </el-table-column> -->
                    <!-- <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                       <el-row style="font-size: 18px;">
                         <el-button type="primary" plain size="small" @click="outboundIn(scope.row)"><i class="el-icon-tickets"> </i> 出库</el-button>
                         <el-button type="danger" plain size="small" @click="deleteRequst(scope.row.id);"><i class="el-icon-delete"> </i> 删除</el-button>
                       </el-row>
                    </template>
                  </el-table-column> -->
                  </el-table>
                  <el-pagination @size-change="handleSizeChange"
                                 @current-change="handleCurrentChange"
                                 :current-page="pageInfo.pageNum"
                                 :page-sizes="[5, 10, 15]"
                                 :page-size="pageInfo.pageSize"
                                 layout="total, sizes, prev, pager, next, jumper"
                                 :total="totalPage">
                  </el-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <!-- 大表格 -->

        </div>
      </div>
    </transition>

    <el-dialog title="确定出库？"
               :visible.sync="outbounddate">
      <div style="color: #0077AA;font-size: 20px;text-align: center;font-weight: 700;"><i class="el-icon-info"></i>请确认无误后出库</div>
      <div style="text-align: center;width: 100%; margin-top: 20px;">
        <el-button @click="outbounddate = false"
                   type="infor"><i class="el-icon-close"></i> 取 消
        </el-button>
        <el-button @click="doDeleteIn"
                   type="danger"
                   plain><i class="el-icon-delete-solid"></i> 出库
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="确定入库？"
               :visible.sync="outboundDelivery">
      <div style="color: #0077AA;font-size: 20px;text-align: center;font-weight: 700;"><i class="el-icon-info"></i>请确认无误后入库</div>
      <div style="text-align: center;width: 100%; margin-top: 20px;">
        <el-button @click="outboundDelivery = false"
                   type="infor"><i class="el-icon-close"></i> 取 消
        </el-button>
        <el-button @click="doDeleteOut"
                   type="danger"
                   plain><i class="el-icon-delete-solid"></i> 入库
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="确定要删除？"
               :visible.sync="deleteMsgVisible">
      <div style="color: #0077AA;font-size: 20px;text-align: center;font-weight: 700;"><i class="el-icon-info"></i>是否继续删除？</div>
      <div style="text-align: center;width: 100%; margin-top: 20px;">
        <el-button @click="deleteMsgVisible = false"
                   type="infor"><i class="el-icon-close"></i> 取 消
        </el-button>
        <el-button @click="doDeleteRequst"
                   type="danger"
                   plain><i class="el-icon-delete-solid"></i> 删 除
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="确定要删除？"
               :visible.sync="deleteMsgtrue">
      <div style="color: #0077AA;font-size: 20px;text-align: center;font-weight: 700;"><i class="el-icon-info"></i>此操作无法复原，是否继续删除？</div>
      <div style="text-align: center;width: 100%; margin-top: 20px;">
        <el-button @click="deleteMsgtrue = false"
                   type="infor"><i class="el-icon-close"></i> 取 消
        </el-button>
        <el-button @click="doTrueDelete"
                   type="danger"
                   plain><i class="el-icon-delete-solid"></i> 删 除
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  mounted () {
    this.getOrderList()

    // this.getProductsList()
    // this.getTradertList()
  },
  methods: {
    formattotalPrice (value) {
      return value.unitPrice * value.number + '.00元'
    },
    formatPrice (val) {
      return val.unitPrice + '.00元'
    },

    // 按照id 修改订单表状态
    async doDeleteRequst () {
      this.deleteMsgVisible = false
      // this.open2()
      console.log(this.deleteMsgId)
      const { data: result } = await this.$http.delete('/order/delete/' + this.deleteMsgId)
      if (result.status !== 200) return this.$message.error('删除失败！')
      this.$message.success('删除成功！')
      this.getOrderList()
    },
    getList () {
      if (this.activeName == 'first') {
        this.getOrderList()
      } else if (this.activeName == 'first2') {
        console.log(this.activeName)
        this.getRemoveList()
      } else if (this.activeName == 'first3') {
        this.getOrderOutList()
      }
    },

    deleteRequst (val) {
      this.deleteMsgVisible = true
      this.deleteMsgId = val
    },

    // 修改出库单删除状态
    async doTrueDelete () {
      this.deleteMsgtrue = false
      // this.open2()
      console.log(this.deleteMsgId)
      const { data: result } = await this.$http.delete('/order/delete/' + this.deleteMsgId)
      if (result.status !== 200) return this.$message.error('删除失败！')
      this.$message.success('删除成功！')
      this.getRemoveList()
    },

    deletetrue (val) {
      this.deleteMsgtrue = true
      this.deleteMsgId = val
    },
    // 入库请求
    async doDeleteOut () {
      this.outboundDelivery = false
      console.log(this.deleteMsgId)
      const { data: result } = await this.$http.delete('/order/outBound/' + this.deleteMsgId)
      if (result.status !== 200) return this.$message.error('入库失败！')
      this.$message.success('入库成功！')
      this.getOrderList()
    },

    // 入库弹出
    outbound (val) {
      this.outboundDelivery = true
      this.deleteMsgId = val.id
      console.log(this.deleteMsgId)
    },

    // 出库请求
    async doDeleteIn () {
      this.outbounddate = false
      console.log(this.deleteMsgId)
      const { data: result } = await this.$http.delete('/order/outBoundIn/' + this.deleteMsgId)
      if (result.status !== 200) return this.$message.error('出库失败！')
      this.$message.success('出库成功！')
      this.getRemoveList()
    },

    // 出库弹出
    outboundIn (val) {
      this.outbounddate = true
      this.deleteMsgId = val.id
      console.log(this.deleteMsgId)
    },

    // 查询出库审批通过的订单
    async getOrderOutList () {
      if (this.type == '出库') {
        this.pageInfo.type = 1
      } else if (this.type == '入库') {
        this.pageInfo.type = 0
      } else { }
      const { data: result } = await this.$http.get('/order/getOrderOut?pageCurrent=' + this.pageInfo.pageNum + '&pageSize=' + this.pageInfo.pageSize,
        { params: this.pageInfo })
      if (result.status !== 200) return this.$message.error('订单列表查询失败')
      // this.$message.success("查询成功！")
      this.OrderOutList = result.data.list
      console.log(this.OrderOutList)
      this.totalPage = result.data.total
    },
    // 查询出库审批通过的订单
    async getRemoveList () {
      const { data: result } = await this.$http.get('/order/getRemove?pageCurrent=' + this.pageInfo.pageNum + '&pageSize=' + this.pageInfo.pageSize,
        { params: this.pageInfo })
      if (result.status !== 200) return this.$message.error('订单列表查询失败')
      // this.$message.success("查询成功！")
      console.log(result.data)
      this.RemoveList = result.data.list
      this.totalPage = result.data.total
    },
    // 查询出入库审批通过的订单
    async getOrderList () {
      const { data: result } = await this.$http.get('/order/getlist?pageCurrent=' + this.pageInfo.pageNum + '&pageSize=' + this.pageInfo.pageSize,
        { params: this.pageInfo })
      if (result.status !== 200) return this.$message.error('订单列表查询失败')
      // this.$message.success("查询成功！")
      console.log(result.data)
      this.OrderList = result.data.list
      this.totalPage = result.data.total
    },

    // 查询产品列表
    async getProductsList () {
      const { data: result } = await this.$http.get('/requests/list')
      if (result.status !== 200) return this.$message.error('产品列表查询失败')
      this.products = result.data
    },
    // 查询供应商列表
    async getTradertList () {
      const { data: result } = await this.$http.get('/requests/list')
      if (result.status !== 200) return this.$message.error('产品列表查询失败')
      this.traders = result.data
    },
    // 订单详情
    showOrderList (request) {
      this.vacationVisible = true
      this.vacationObj.processors = request.processors
      console.log(request)
    },
    dialogClick () {
      this.OrderRequests = {
        type: '',
        number: '',

        status: false,
        processors: '',
        startType: '',
        endType: '',
        remark: '',
        total: ''
      }
    },
    handleClick (tab, event) {
      this.getList()
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex === 1) {
        return 'warning-row'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    },
    // 新增请求
    async sendRequests () {
      this.dialogFormVisible = false
      this.UserRequests.type = this.aplayActiveName
      const userId = window.sessionStorage.getItem('userId')
      this.UserRequests.userId = userId
      this.UserRequests.processors = userId
      console.log(this.UserRequests)
      const { data: result } = await this.$http.post('/requests/add', this.UserRequests)

      if (result.status !== 200) return this.$message.error('申请发送失败！')
      this.$message.success('新增申请成功！')
      this.getRequestList()
    },
    // timeFormat(val){
    //   return this.moment(val).format("YYYY年MM月DD日")
    // },
    handleSizeChange (val) {
      this.pageInfo.pageSize = val
      this.getList()
      // console.log(this.pageInfo);
    },
    handleCurrentChange (val) {
      this.pageInfo.pageNum = val
      this.getList()
      // console.log(`当前页: ${val}`);
    },
    open1 () {
      this.$notify({
        title: '成功',
        message: '操作成功！',
        type: 'success'
      })
    },

    open2 () {
      this.$notify({
        title: '警告',
        message: '请考虑后在操作！',
        type: 'warning'
      })
    },

    open3 () {
      this.$notify.info({
        title: '消息',
        message: '这是一条消息的提示消息'
      })
    },

    open4 () {
      this.$notify.error({
        title: '错误',
        message: '操作失败！'
      })
    }
  },
  data () {
    return {
      deleteMsgId: '',
      deleteMsgVisible: false,
      deleteMsgtrue: false,
      outboundDelivery: false,
      outbounddate: false,
      OrderList: '', // 状态为0的订单
      RemoveList: '', // 状态为1的订单
      OrderOutList: '', // 状态为2成功出入库的定单
      products: '', // 产品列表
      traders: '', // 供应商列表
      type: '出库',
      pageInfo: {
        userId: '',
        query: '',
        type: '1',
        pageNum: 1,
        pageSize: 10
      },
      vacationObj: {
        processors: [2, 3]
      },
      vacationVisible: false,
      options: [{
        value: '餐饮',
        label: '餐饮'
      }, {
        value: '行程',
        label: '行程'
      }, {
        value: '住宿',
        label: '住宿'
      }, {
        value: '团建',
        label: '团建'
      }, {
        value: '医疗',
        label: '医疗'
      }],
      aplayActiveName: '1',
      activeName: 'first',
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      UserRequests: {
        userId: '',
        type: 1,
        status: false,
        processors: '',
        startType: '',
        remark: '',
        endType: '',
        total: ''
      },
      formLabelWidth: '90px',
      dialogFormVisible: false,
      show: true,
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      totalPage: 4,
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: false
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: true
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: false
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄',
        status: true
      }]
    }
  }
}
</script>

<style scoped lang="less">
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
