import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

import * as echarts from 'echarts'
import './assets/layui/layui.js'
import './assets/layui/css/layui.css'
// import './assets/css/index.css'
import './assets/css/global.css'
import './assets/ali-icon/iconfont.css'
import './assets/css/detailsmusic.css'

import './assets/css/bootstrap.min.css'
import './assets/css/font-awesome.css'
// Morris
import './assets/css/plugins/morris/morris-0.4.3.min.css'

// Gritter
import '../public/js/plugins/gritter/jquery.gritter.css'

import './assets/css/animate.css'
import './assets/css/style.css'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

import axios from 'axios'
Vue.prototype.$echarts = echarts
// axios.defaults.baseURL = 'http://1.116.138.154:801/'
// axios.defaults.baseURL = 'http://erp.baochaofei.cn/'
Vue.prototype.$http = axios

Vue.config.productionTip = false
Vue.use(VueQuillEditor)
// Vue.use($)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
